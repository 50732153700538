<template>
  <validation-observer
    v-slot="{ invalid }"
    ref="observer"
  >
    <v-card elevation="0">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submitAppQa"
      >
        <div
          v-for="question in filteredQuestions"
          :key="question.questionID"
        >
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="9"
              md="9"
            >
              <div v-if="question.answer_category === 5">
                <p>{{ question.question_text }}</p>
              </div>
              <div v-if="question.questionID === 1002">
                <p>{{ question.question_text | capitalize }}</p>
                <div class="d-inline-flex">
                  <v-col
                    cols="6"
                    class="ml-0 pl-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Feet"
                      rules="required|numeric|min_value:4|max_value:7"
                    >
                      <v-text-field
                        v-model="surveyAnswers.feet"
                        v-mask="'#'"
                        outlined
                        label="Feet"
                        color="primary"
                        :error-messages="errors"
                        @blur="formatHeight(question)"
                        @click="
                          clickedFieldGlobal(
                            'Underwriting feet',
                            43,
                            'Afficiency Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    class="ml-0 pl-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Inches"
                      :rules="{ required: true, regex: /\b([0-9]|1[0-1])\b/ }"
                    >
                      <v-text-field
                        v-model="surveyAnswers.inches"
                        v-mask="'##'"
                        outlined
                        label="inches"
                        color="primary"
                        :error-messages="errors"
                        @blur="formatHeight(question), showAnswers()"
                        @click="
                          clickedFieldGlobal(
                            'Underwriting inches',
                            44,
                            'Afficiency Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </div>
              </div>
              <div v-if="question.questionID === 1003">
                <p>{{ question.question_text | capitalize }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Weight"
                    rules="required|numeric|min_value:0|max_value:600"
                  >
                    <v-text-field
                      v-model="surveyAnswers[question.questionID]"
                      v-mask="'###'"
                      :error-messages="errors"
                      outlined
                      label="Pounds"
                      @blur="
                        answerMap.set(question.questionID, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[question.questionID]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          'Underwriting weight',
                          45,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </div>
              <!------------------------------------NON REFLEXIVES----------------------------------------->
              <div v-if="nonRelfexiveBools.includes(question.questionID)">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  class="d-flex align-center"
                  @change="showAnswers()"
                >
                  <v-radio
                    v-for="value in [1, 0]"
                    :key="value"
                    color="blanketbutton"
                    text
                    :label="value === 1 ? 'Yes' : 'No'"
                    outlined
                    :value="value.toString()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} ${
                          value === 1 ? 'true' : 'false'
                        }`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: value.toString()
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!------------------------------------------Q1260 Residency------------------------------------------------->
              <div v-if="question.questionID === 1260">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[1260]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle1260()"
                >
                  <v-radio
                    v-for="value in [1, 0]"
                    :key="value"
                    color="blanketbutton"
                    text
                    :label="value === 1 ? 'Yes' : 'No'"
                    outlined
                    :value="value.toString()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} ${
                          value === 1 ? 'true' : 'false'
                        }`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: value.toString()
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------------------Q1261 VISA------------------------------------>
              <div
                v-if="
                  question.questionID === 1261 && surveyAnswers[1260] === '0'
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[1261]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle1261()"
                >
                  <v-radio
                    v-for="value in [1, 0]"
                    :key="value"
                    color="blanketbutton"
                    text
                    :label="value === 1 ? 'Yes' : 'No'"
                    outlined
                    :value="value.toString()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} ${
                          value === 1 ? 'true' : 'false'
                        }`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: value.toString()
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------------------------------1262 Type of Visa------------------------------------------------>
              <div
                v-if="
                  question.questionID === 1262 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[1262]"
                    :error-messages="errors"
                    :items="q1262Answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      }),
                      handle1262()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!--------------------------------1262 Other-------------------------------->
              <div
                v-if="
                  question.questionID === 1262 && surveyAnswers[1262] === 12623
                "
              >
                <p>Please provide details of other</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[1266]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(1262, {
                        questionId: '1262',
                        answerOptionId: '1266',
                        answerValue: surveyAnswers[1266]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!------------------------------------------Work Visa------------------------------------------------->
              <div
                v-if="
                  question.questionID === 1263 &&
                    surveyAnswers[question.Parent_question_id] ===
                    parseInt(question.applicable_parent_answer)
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[1263]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------------------Q1264 PERMANENT------------------------------------>
              <div
                v-if="
                  question.questionID === 1264 &&
                    surveyAnswers[1263] ===
                    '1'
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[1264]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------------------Q1265 HEALTHCARE------------------------------------>
              <div
                v-if="
                  question.questionID === 1265 &&
                    surveyAnswers[1263] ===
                    '1'
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[1265]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!------------------------------------Q1120 MILITARY-------------------------------->
              <div v-if="question.questionID === 1120">
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle1120()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------Q1960 MILITARY SELECT--------------------->
              <div
                v-if="
                  question.questionID === 1960 &&
                    surveyAnswers[question.Parent_question_id.toString()] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[1960]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      }),
                      handle1960()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!-------------------Q1961 MILITARY SELECT--------------------->
              <div
                v-if="
                  question.questionID === 1961 && surveyAnswers[1960] === 19602
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!------------------------------EMPLOYMENT------------------------>
              <div v-if="question.questionID === 1020">
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle1020()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------1021 EMPLOYER--------------------->
              <div
                v-if="
                  question.questionID === 1021 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!-------------------1022 OCCUPATION--------------------->
              <div
                v-if="
                  question.questionID === 1022 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!-------------------1990 SEEKING WORK--------------------->
              <div
                v-if="
                  question.questionID === 1990 &&
                    surveyAnswers[question.Parent_question_id] ===
                    parseInt(question.applicable_parent_answer)
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------------Q2000 SEEKING WORK------------------->
              <div
                v-if="
                  question.questionID === 2000 && surveyAnswers[1990] === '1'
                "
              >
                <p>{{ question.question_text }}</p>

                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    label="Months"
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!-------------------------Q1040 DISABILITY------------------->
              <div v-if="question.questionID === 1040">
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle1040()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------------Q1150 PARENTAL DISEASE------------------->
              <div
                v-if="
                  question.questionID === 1150 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                      answerMap.delete(1160);
                      surveyAnswers[1160] = null;
                    "
                  />
                </v-radio-group>
              </div>
              <!------------------------------- 1130 NOT EMPLOYED ------------------------------------>
              <div
                v-if="
                  question.questionID === 1130 && surveyAnswers[1020] === '0'
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      }),
                      handle1130()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!-------------------------Q1135 EDUCATION------------------->
              <div
                v-if="
                  question.questionID === 1135 &&
                    surveyAnswers[question.Parent_question_id] ===
                    parseInt(question.applicable_parent_answer)
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      })
                    "
                    @blur="showAnswers"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                  </validation-provider>
                </validation-provider>
              </div>
              <!-------------------------WEIGHT CHANGE---------------------------->
              <div v-if="question.questionID === 3700">
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle3700()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------------Q3701 WEIGHT GAINED---------------------------->
              <div
                v-if="
                  question.questionID === 3701 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text | capitalize }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gained"
                    rules="required|numeric|min_value:0|max_value:600"
                  >
                    <v-text-field
                      v-model="surveyAnswers[question.questionID]"
                      v-mask="'###'"
                      :error-messages="errors"
                      outlined
                      label="Pounds"
                      @blur="
                        answerMap.set(question.questionID, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[question.questionID]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </div>
              <!-------------------------Q3702 WEIGHT LOST---------------------------->
              <div
                v-if="
                  question.questionID === 3702 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text | capitalize }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Lost"
                    rules="required|numeric|min_value:0|max_value:600"
                  >
                    <v-text-field
                      v-model="surveyAnswers[question.questionID]"
                      v-mask="'###'"
                      :error-messages="errors"
                      outlined
                      label="Pounds"
                      @blur="
                        answerMap.set(question.questionID, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[question.questionID]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </div>
              <!-------------------------Q3703 REASON FOR WEIGHT CHANGE---------------------------->
              <div
                v-if="
                  question.questionID === 3703 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :error-messages="errors"
                    :rules="[v => v.length > 0 || 'This field is required']"
                    :items="q3703Answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    multiple
                    label="Select all that apply"
                    outlined
                    @blur="handle3703(), showAnswers()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
                <!-------------------------Q370361 OTHER---------------------------->
                <div
                  v-if="
                    surveyAnswers[3703] && surveyAnswers[3703].includes(37036)
                  "
                >
                  <p>Please provide details of other</p>
                  <v-col
                    cols="12"
                    sm="4"
                    class="ml-0 pl-0"
                  >
                    <v-text-field
                      v-model="surveyAnswers[370361]"
                      :rules="[v => !!v || 'This field is required']"
                      outlined
                      @blur="
                        answerMap.set(370361, {
                          questionId: question.questionID.toString(),
                          answerOptionId: '370361',
                          answerValue: surveyAnswers[370361]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </v-col>
                </div>
              </div>
              <!-------------------------Q1080 TOBACCO-------------------------------->
              <div v-if="question.questionID === 1080">
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :rules="[v => !!v || 'This field is required']"
                    :error-messages="errors"
                    :items="q1080Items"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      })
                    "
                    @blur="showAnswers"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!-------------------------Q2020 DIABILITY CLAIM---------------------------->
              <div
                v-if="
                  question.questionID === 2020 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerOptionId: surveyAnswers[
                          question.questionID
                        ].toString(),
                        answerValue: '1'
                      })
                    "
                    @blur="showAnswers"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!---------------------Q2025 LENGTH OF DISABILITY------------------------->
              <div
                v-if="
                  question.questionID === 2025 &&
                    JSON.parse(question.applicable_parent_answer).includes(
                      surveyAnswers[question.Parent_question_id]
                    )
                "
              >
                <p>{{ question.question_text }}</p>

                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    label="Months"
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!-------------------------3710 Parental Disease---------------------------->
              <div v-if="question.questionID === 3710">
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[question.questionID]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers()"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: '0'
                      });
                      answerMap.delete(1150);
                      surveyAnswers[1150] = null;
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------------------Q3720 ADVISED MEDICAL---------------------------------->
              <div v-if="question.questionID === 3720">
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[question.questionID]"
                    :items="q3720Answers"
                    :rules="[v => v.length > 0 || 'This field is required']"
                    :error-messages="errors"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    multiple
                    label="Select all that apply"
                    outlined
                    @change="handle3720()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
                <!-------------------------TYPE OF SURGERY---------------------------->
                <div v-if="show37201">
                  <p>{{ answerText(3720, 372011) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[372011]"
                      :items="q3720Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      :error-messages="errors"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle372011()"
                      @blur="showAnswers"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-------------------------TYPE OF TEST---------------------------->
                <div v-if="show37202">
                  <p>{{ answerText(3720, 372021) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[372021]"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      :error-messages="errors"
                      :items="q37202Items"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle372021()"
                      @blur="showAnswers"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!---------------------SURGERY OTHER-------------------->
                <div
                  v-if="
                    surveyAnswers[372011] &&
                      surveyAnswers[372011].includes(3720121)
                  "
                >
                  <p>{{ answerText(3720, 37201211) }}</p>
                  <v-col
                    cols="12"
                    sm="4"
                    class="ml-0 pl-0"
                  >
                    <v-text-field
                      v-model="surveyAnswers[37201211]"
                      :rules="[v => !!v || 'This field is required']"
                      outlined
                      @blur="
                        answerMap.set(37201211, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[37201211]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </v-col>
                </div>
                <!---------------------TESTS OTHER-------------------->
                <div
                  v-if="
                    surveyAnswers[372021] &&
                      surveyAnswers[372021].includes(37202112)
                  "
                >
                  <p>{{ answerText(3720, 372021121) }}</p>
                  <v-col
                    cols="12"
                    sm="4"
                    class="ml-0 pl-0"
                  >
                    <v-text-field
                      v-model="surveyAnswers[372021121]"
                      :rules="[v => !!v || 'This field is required']"
                      outlined
                      @blur="
                        answerMap.set(372021121, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[372021121]
                        }),
                        showAnswers()
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </v-col>
                </div>
              </div>
              <!---------------------------Q3730 DISORDERS------------------------------->
              <div v-if="question.questionID === 3730">
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[3730]"
                    :items="q3730Answers"
                    :error-messages="errors"
                    :rules="[v => v.length > 0 || 'This field is required']"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    multiple
                    label="Select all that apply"
                    outlined
                    @change="handle3730()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
                <!----------------------------373011 MENTAL DISORDERS----------------------------->
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(37301)
                  "
                >
                  <p>{{ answerText(3730, 373011) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[373011]"
                      :error-messages="errors"
                      :items="q373011Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle373011"
                      @blur="showAnswers"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q37301111 THERAPIST------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730111)
                  "
                >
                  <p>{{ answerText(3730, 37301111) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37301111]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="handle37301111()"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301111, {
                          questionId: '3730',
                          answerOptionId: '37301111',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301111, {
                          questionId: '3730',
                          answerOptionId: '37301111',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q373011116 THERAPIST------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730116)
                  "
                >
                  <p>{{ answerText(3730, 373011116) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[373011116]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="handle373011116()"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(373011116, {
                          questionId: '3730',
                          answerOptionId: '373011116',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(373011116, {
                          questionId: '3730',
                          answerOptionId: '373011116',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q373011111 SELECT THERAPIST------------------------>
                <div
                  v-if="
                    surveyAnswers[37301111] && surveyAnswers[37301111] === '1'
                  "
                >
                  <p>{{ answerText(3730, 373011111) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[373011111]"
                      :error-messages="errors"
                      :items="q37301111Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle373011111()"
                      @blur="showAnswers"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q3730111116 SELECT THERAPIST------------------------>
                <div
                  v-if="
                    surveyAnswers[373011116] && surveyAnswers[373011116] === '1'
                  "
                >
                  <p>{{ answerText(3730, 3730111116) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[3730111116]"
                      :error-messages="errors"
                      :items="q3730111116Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle3730111116()"
                      @blur="showAnswers"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q37301111131 OTHER------------------------>
                <div
                  v-if="
                    surveyAnswers[373011111] &&
                      surveyAnswers[373011111].includes(3730111113)
                  "
                >
                  <p>{{ answerText(3730, 37301111131) }}</p>
                  <v-text-field
                    v-model="surveyAnswers[37301111131]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(37301111131, {
                        questionId: '3730',
                        answerValue: surveyAnswers[37301111131]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </div>
                <!-----------------------Q3730111116 OTHER------------------------>
                <div
                  v-if="
                    surveyAnswers[3730111116] &&
                      surveyAnswers[3730111116].includes(37301111136)
                  "
                >
                  <p>{{ answerText(3730, 373011111316) }}</p>
                  <v-text-field
                    v-model="surveyAnswers[373011111316]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(373011111316, {
                        questionId: '3730',
                        answerValue: surveyAnswers[373011111316]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </div>
                <!-----------------------Q373011112 LOSS OF WORK------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730111)
                  "
                >
                  <p>{{ answerText(3730, 373011112) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37301112]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301112, {
                          questionId: '3730',
                          answerOptionId: '37301112',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301112, {
                          questionId: '3730',
                          answerOptionId: '37301112',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q3730111126 LOSS OF WORK------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730116)
                  "
                >
                  <p>{{ answerText(3730, 3730111126) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[3730111126]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730111126, {
                          questionId: '3730',
                          answerOptionId: '3730111126',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730111126, {
                          questionId: '3730',
                          answerOptionId: '3730111126',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q373011113 MEDICATIONS------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730111)
                  "
                >
                  <p>{{ answerText(3730, 373011113) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37301113]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301113, {
                          questionId: '3730',
                          answerOptionId: '37301113',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37301113, {
                          questionId: '3730',
                          answerOptionId: '37301113',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q3730111136 MEDICATIONS------------------------>
                <div
                  v-if="
                    surveyAnswers[373011] &&
                      surveyAnswers[373011].includes(3730116)
                  "
                >
                  <p>{{ answerText(3730, 3730111136) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[3730111136]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730111136, {
                          questionId: '3730',
                          answerOptionId: '3730111136',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730111136, {
                          questionId: '3730',
                          answerOptionId: '3730111136',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!--------------------------------Q373021 INSULIN----------------------------------->
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(37302)
                  "
                >
                  <p>{{ answerText(3730, 373021) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[373021]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(373021, {
                          questionId: '3730',
                          answerOptionId: '373021',
                          answerValue: '1'
                        });
                        surveyAnswers[3730211] = null;
                        surveyAnswers[3730212] = null;
                        answerMap.delete(3730211);
                        answerMap.delete(3730212);
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(373021, {
                          questionId: '3730',
                          answerOptionId: '373021',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q3730211 ORAL MEDICATION------------------------>
                <div
                  v-if="surveyAnswers[373021] && surveyAnswers[373021] === '0'"
                >
                  <p>{{ answerText(3730, 3730211) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[3730211]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730211, {
                          questionId: '3730',
                          answerOptionId: '3730211',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730211, {
                          questionId: '3730',
                          answerOptionId: '3730211',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q3730212 A1C------------------------>
                <div
                  v-if="surveyAnswers[373021] && surveyAnswers[373021] === '0'"
                >
                  <p>{{ answerText(3730, 3730212) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[3730212]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730212, {
                          questionId: '3730',
                          answerOptionId: '3730212',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730212, {
                          questionId: '3730',
                          answerOptionId: '3730212',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q373031 ASTHMA SELECT------------------------>
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(37303)
                  "
                >
                  <p>{{ answerText(3730, 373031) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[373031]"
                      :error-messages="errors"
                      :items="q373031Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle373031"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q37303111 ASTHMA CARE------------------------>
                <div
                  v-if="
                    surveyAnswers[373031] &&
                      surveyAnswers[373031].includes(3730311)
                  "
                >
                  <p>{{ answerText(3730, 37303111) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37303111]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37303111, {
                          questionId: '3730',
                          answerOptionId: '37303111',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37303111, {
                          questionId: '3730',
                          answerOptionId: '37303111',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q37303121 SLEEP APNEA------------------------>
                <div
                  v-if="
                    surveyAnswers[373031] &&
                      surveyAnswers[373031].includes(3730312)
                  "
                >
                  <p>{{ answerText(3730, 37303121) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37303121]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37303121, {
                          questionId: '3730',
                          answerOptionId: '37303121',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37303121, {
                          questionId: '3730',
                          answerOptionId: '37303121',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q373081 NEURO------------------------>
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(37308)
                  "
                >
                  <p>{{ answerText(3730, 373081) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[373081]"
                      :error-messages="errors"
                      :items="q373081Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle373081"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q37308181 OTHER------------------------>
                <div
                  v-if="
                    surveyAnswers[373081] &&
                      surveyAnswers[373081].includes(3730818)
                  "
                >
                  <p>{{ answerText(3730, 37308181) }}</p>
                  <v-text-field
                    v-model="surveyAnswers[37308181]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(37308181, {
                        questionId: '3730',
                        answerOptionId: '37308181',
                        answerValue: surveyAnswers[37308181]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </div>
                <!-----------------------Q37308162 SEIZURES------------------------>
                <div
                  v-if="
                    surveyAnswers[373081] &&
                      surveyAnswers[373081].includes(3730816)
                  "
                >
                  <p>{{ answerText(3730, 37308162) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[37308162]"
                      :error-messages="errors"
                      :items="q37308162Items"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      multiple
                      label="Select all that apply"
                      outlined
                      @change="handle37308162"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!-----------------------Q3730816231 OTHER SEIZURES------------------------>
                <div
                  v-if="
                    surveyAnswers[37308162] &&
                      surveyAnswers[37308162].includes(373081623)
                  "
                >
                  <p>{{ answerText(3730, 3730816231) }}</p>
                  <v-text-field
                    v-model="surveyAnswers[3730816231]"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(3730816231, {
                        questionId: '3730',
                        answerOptionId: '3730816231',
                        answerValue: surveyAnswers[3730816231]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </div>
                <!-----------------------Q3730816211 LAST SEIZURE------------------------>
                <div
                  v-if="
                    surveyAnswers[37308162] &&
                      (surveyAnswers[37308162].includes(373081621) ||
                        surveyAnswers[37308162].includes(373081622))
                  "
                >
                  <p>{{ answerText(3730, 3730816211) }}</p>
                  <v-text-field
                    v-model="surveyAnswers[3730816211]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(3730816211, {
                        questionId: '3730',
                        answerOptionId: '3730816211',
                        answerValue: surveyAnswers[3730816211]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </div>
                <!-----------------------Q3730101 ARTHRITIS------------------------>
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(373010)
                  "
                >
                  <p>{{ answerText(3730, 3730101) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[3730101]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730101, {
                          questionId: '3730',
                          answerOptionId: '3730101',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(3730101, {
                          questionId: '3730',
                          answerOptionId: '3730101',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <!-----------------------Q3730101 IBD------------------------>
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(3730011)
                  "
                >
                  <p>{{ answerText(3730, 37300111) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37300111]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37300111, {
                          questionId: '3730',
                          answerOptionId: '37300111',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37300111, {
                          questionId: '3730',
                          answerOptionId: '37300111',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
                <div
                  v-if="
                    surveyAnswers[3730] && surveyAnswers[3730].includes(3730011)
                  "
                >
                  <p>{{ answerText(3730, 37300112) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37300112]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37300112, {
                          questionId: '3730',
                          answerOptionId: '37300112',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37300112, {
                          questionId: '3730',
                          answerOptionId: '37300112',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
              </div>
              <!-----------------------Q3750 HOSPITALIZATION------------------------>
              <div v-if="question.questionID === 3750">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[3750]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3750, {
                        questionId: '3750',
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3750, {
                        questionId: '3750',
                        answerValue: '0'
                      });
                      surveyAnswers[3761] = null;
                      surveyAnswers[3762] = null;
                      answerMap.delete(3761);
                      answerMap.delete(3762);
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------Q3761 HOSPITALIZATION------------------------>
              <div
                v-if="
                  question.questionID === 3761 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[3761]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3761, {
                        questionId: '3761',
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3761, {
                        questionId: '3761',
                        answerValue: '0'
                      });
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------Q3762 HOSPITALIZATION------------------------>
              <div
                v-if="
                  question.questionID === 3762 &&
                    surveyAnswers[question.Parent_question_id] ===
                    question.applicable_parent_answer
                "
              >
                <p>{{ question.question_text }}</p>
                <v-text-field
                  v-model="surveyAnswers[3762]"
                  :rules="[v => !!v || 'This field is required']"
                  outlined
                  @blur="
                    answerMap.set(3762, {
                      questionId: '3762',
                      answerValue: surveyAnswers[3762]
                    });
                    showAnswers();
                  "
                  @click="
                    clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )
                  "
                ></v-text-field>
              </div>
              <!-----------------------Q3770 HOBBIES------------------------>
              <div v-if="question.questionID === 3770">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[3770]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle3770"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3770, {
                        questionId: '3770',
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3770, {
                        questionId: '3770',
                        answerValue: '0'
                      });
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!-----------------------Q3771 HOBBIES------------------------>
              <div
                v-if="
                  question.questionID === 3771 && surveyAnswers[3770] === '1'
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[3771]"
                    :error-messages="errors"
                    :items="q3771Items"
                    :rules="[v => v.length > 0 || 'This field is required']"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    multiple
                    label="Select all that apply"
                    outlined
                    @change="handle3771"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
                <!------------------------------------Q377121 SCUBA-------------------------------------->
                <div
                  v-if="
                    surveyAnswers[3771] && surveyAnswers[3771].includes(37712)
                  "
                >
                  <p>{{ answerText(3771, 377121) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[377121]"
                      :error-messages="errors"
                      :items="q377121Items"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      label="Select all that apply"
                      outlined
                      @change="
                        answerMap.set(377121, {
                          questionId: '3771',
                          answerOptionId: surveyAnswers[377121].toString(),
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!------------------------------------Q377131 ROCK CLIMBING-------------------------------------->
                <div
                  v-if="
                    surveyAnswers[3771] && surveyAnswers[3771].includes(37713)
                  "
                >
                  <p>{{ answerText(3771, 377131) }}</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Above"
                    rules="required"
                  >
                    <v-select
                      v-model="surveyAnswers[377131]"
                      :error-messages="errors"
                      :items="q377131Items"
                      item-text="answer_text"
                      item-value="answer_option_id"
                      label="Select all that apply"
                      outlined
                      @change="
                        answerMap.set(377131, {
                          questionId: '3771',
                          answerOptionId: surveyAnswers[377131].toString(),
                          answerValue: '1'
                        }),
                        handle377131
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </div>
                <!------------------------------------Q37713111 SAFETY EQUIPMENT-------------------------------------->
                <div
                  v-if="
                    surveyAnswers[377131] && surveyAnswers[377131] === 3771311
                  "
                >
                  <p>{{ answerText(3771, 37713111) }}</p>
                  <v-radio-group
                    v-model="surveyAnswers[37713111]"
                    :rules="[v => !!v || 'This field is required']"
                    row
                    @change="showAnswers"
                  >
                    <v-radio
                      color="blanketbutton"
                      text
                      label="Yes"
                      outlined
                      value="1"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} true`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37713111, {
                          questionId: '3771',
                          answerValue: '1'
                        });
                        showAnswers();
                      "
                    />
                    <v-radio
                      color="blanketbutton"
                      text
                      label="No"
                      value="0"
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text} false`,
                          -1,
                          'Afficiency Survey v1'
                        );
                        answerMap.set(37713111, {
                          questionId: '3771',
                          answerValue: '0'
                        });
                        showAnswers();
                      "
                    />
                  </v-radio-group>
                </div>
              </div>
              <!------------------------------------Q3780 SUBSTANCES-------------------------------------->
              <div v-if="question.questionID === 3780">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[3780]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3780, {
                        questionId: '3780',
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3780, {
                        questionId: '3780',
                        answerValue: '0'
                      });
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!------------------------------------Q3781 ALCOHOL-------------------------------------->
              <div v-if="question.questionID === 3781">
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!------------------------------------Q3782 MARIJUANA-------------------------------------->
              <div v-if="question.questionID === 3782">
                <p>{{ question.question_text }}</p>
                <v-radio-group
                  v-model="surveyAnswers[3782]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="handle3782"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3782, {
                        questionId: '3782',
                        answerValue: '1'
                      });
                      showAnswers();
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3782, {
                        questionId: '3782',
                        answerValue: '0'
                      });
                      showAnswers();
                    "
                  />
                </v-radio-group>
              </div>
              <!------------------------------------Q3783 MARIJUANA FREQUENCY-------------------------------------->
              <div
                v-if="
                  question.questionID === 3783 &&
                    surveyAnswers[3782] &&
                    surveyAnswers[3782] === '1'
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[3783]"
                    :error-messages="errors"
                    :items="question.answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    label="Select all that apply"
                    outlined
                    @change="
                      answerMap.set(3783, {
                        questionId: '3783',
                        answerOptionId: surveyAnswers[3783].toString(),
                        answerValue: '1'
                      }),
                      handle377131
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <!------------------------------------Q3784 WEEKLY USE-------------------------------------->
              <div
                v-if="
                  question.questionID === 3784 &&
                    surveyAnswers[3783] &&
                    surveyAnswers[3783] === 37832
                "
              >
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!------------------------------------Q3784 MONTHLY USE-------------------------------------->
              <div
                v-if="
                  question.questionID === 3785 &&
                    surveyAnswers[3783] &&
                    surveyAnswers[3783] === 37833
                "
              >
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="surveyAnswers[question.questionID]"
                    v-mask="'##'"
                    :rules="[v => !!v || 'This field is required']"
                    outlined
                    @blur="
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      }),
                      showAnswers()
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </div>
              <!----------------------------------Q3805 MOVING VIOLATIONS-------------------------------------->
              <div v-if="question.questionID === 3805">
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[3805]"
                    :error-messages="errors"
                    :items="q3805Answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(3805, {
                        questionId: '3805',
                        answerOptionId: surveyAnswers[3805].toString(),
                        answerValue: '1'
                      })
                    "
                    @input="handle3805()"
                    @blur="showAnswers"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <div
                v-if="
                  question.questionID === 3815 &&
                    drivingViolations.includes(surveyAnswers[3805])
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[3815]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3815, {
                        questionId: '3815',
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3815, {
                        questionId: '3815',
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!---------------------------Q3800 MOVING VIOLATIONS---------------------------->
              <div v-if="question.questionID === 3800">
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Above"
                  rules="required"
                >
                  <v-select
                    v-model="surveyAnswers[3800]"
                    :error-messages="errors"
                    :items="q3800Answers"
                    item-text="answer_text"
                    item-value="answer_option_id"
                    outlined
                    @change="
                      answerMap.set(3800, {
                        questionId: '3800',
                        answerOptionId: surveyAnswers[3800].toString(),
                        answerValue: '1'
                      })
                    "
                    @blur="handle3800(), showAnswers()"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-select>
                </validation-provider>
              </div>
              <div
                v-if="
                  question.questionID === 3810 &&
                    drivingViolations.includes(surveyAnswers[3800])
                "
              >
                <p>{{ question.question_text }}</p>

                <v-radio-group
                  v-model="surveyAnswers[3810]"
                  :rules="[v => !!v || 'This field is required']"
                  row
                  @change="showAnswers"
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    label="Yes"
                    outlined
                    value="1"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3810, {
                        questionId: '3810',
                        answerValue: '1'
                      });
                    "
                  />
                  <v-radio
                    color="blanketbutton"
                    text
                    label="No"
                    value="0"
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      answerMap.set(3810, {
                        questionId: '3810',
                        answerValue: '0'
                      });
                    "
                  />
                </v-radio-group>
              </div>
              <!-------------------------------------Q1030 INCOME------------------------------------------->
              <div v-if="question.questionID === 1030">
                <p>{{ question.question_text }}</p>
                <v-col
                  cols="12"
                  sm="4"
                  class="ml-0 pl-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Income"
                    rules="required"
                  >
                    <v-text-field
                      v-model="surveyAnswers[1030]"
                      v-mask="'#########'"
                      prepend-icon="mdi-currency-usd"
                      :error-messages="errors"
                      outlined
                      @blur="
                        showAnswers();
                        answerMap.set(question.questionID, {
                          questionId: question.questionID.toString(),
                          answerValue: surveyAnswers[question.questionID]
                        });
                      "
                      @click="
                        clickedFieldGlobal(
                          `${question.question_text}`,
                          -1,
                          'Afficiency Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </div>
              <!---------------------1980 INCOME------------------------->
              <div
                v-if="
                  question.questionID === 1980 &&
                    surveyAnswers[question.Parent_question_id] ===
                    parseInt(question.applicable_parent_answer)
                "
              >
                <p>{{ question.question_text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Income"
                  rules="required"
                >
                  <v-text-field
                    v-model="surveyAnswers[1980]"
                    v-mask="'#########'"
                    prepend-icon="mdi-currency-usd"
                    :error-messages="errors"
                    outlined
                    @blur="
                      showAnswers,
                      answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: surveyAnswers[question.questionID]
                      });
                      showAnswers();
                    "
                    @click="
                      clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )
                    "
                  ></v-text-field>
                </validation-provider>
              </div>
              <!------------------------------------------------------------------------------------------->
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn
            x-large
            class="orange white--text"
            to="/"
            @click="
              clickedFieldGlobal(
                'Underwriting Questions, Cancel',
                -1,
                'Afficiency Survey v1'
              )
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-large
            :disabled="loading"
            @click="
              submitAppQa();
              clickedFieldGlobal(
                'Underwriting Questions, Next',
                -1,
                'Afficiency Survey v1'
              );
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
  export default {
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    props: ['questions', 'user', 'nextQuestion', 'surveyAnswers', 'answerMap'],
    data () {
      return {
        activelySeekingWork: false,
        valid: true,
        feet: '',
        inches: '',
        nonRelfexiveBools: [
          1010,
          1011,
          1240,
          1060,
          1061,
          1270,
          1280,
          3715,
          3163,
          3790,
          3740
        ],
        drivingViolations: [
          38052,
          38053,
          38054,
          38055,
          38056,
          38002,
          38003,
          38004,
          38005,
          38006
        ]
      }
    },
    computed: {
      q377131Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3771
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '377131') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q377121Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3771
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '377121') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3771Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3771
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === null) {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q37308162Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '37308162') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q373081Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '373081') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q373031Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '373031') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3730111116Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '3730111116') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q37301111Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '373011111') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3703Answers () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3703
        )
        let answers = question.answers
        let list = []
        answers.forEach(answer => {
          if (answer.answer_category === null) {
            list.push(answer)
          }
        })
        return list
      },
      q1262Answers () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 1262
        )
        let answers = question.answers
        let list = []
        answers.forEach(answer => {
          if (answer.answer_category === null) {
            list.push(answer)
          }
        })
        return list
      },
      q1080Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 1080
        )
        let answers = question.answers
        answers.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        return answers
      },
      show37201 () {
        if (
          this.surveyAnswers[3720] &&
          this.surveyAnswers[3720].includes(37201) &&
          !this.surveyAnswers[3720].includes(37203)
        ) {
          return true
        } else {
          return false
        }
      },
      show37202 () {
        if (
          this.surveyAnswers[3720] &&
          this.surveyAnswers[3720].includes(37202) &&
          !this.surveyAnswers[3720].includes(37203)
        ) {
          return true
        } else {
          return false
        }
      },
      show37204 () {
        if (
          this.surveyAnswers[3720] &&
          this.surveyAnswers[3720].includes(37204) &&
          !this.surveyAnswers[3720].includes(37203)
        ) {
          return true
        } else {
          return false
        }
      },
      q37202Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3720
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '372021') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3720Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3720
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '372011') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3720Answers () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3720
        )
        let answers = question.answers
        let options = []
        answers.slice(0, 3).forEach(answer => {
          options.push(answer)
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q373011Items () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === '373011') {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3730Answers () {
        let question = this.filteredQuestions.find(
          question => question.questionID === 3730
        )
        let answers = question.answers
        let options = []
        answers.forEach(answer => {
          if (answer.Parent_answer_option_id === null) {
            options.push(answer)
          }
          options.sort((a, b) => a.answer_option_seq - b.answer_option_seq)
        })
        return options
      },
      q3805Answers () {
        let answers = ''
        let question = this.filteredQuestions.find(
          question => question.questionID === 3805
        )
        if (question) {
          answers = question.answers
        }
        return answers
      },
      q3800Answers () {
        let answers = ''
        let question = this.filteredQuestions.find(
          question => question.questionID === 3800
        )
        if (question) {
          answers = question.answers
        }
        return answers
      },
      arcId () {
        return this.$store.getters.getArcId
      },
      loading () {
        return this.$store.getters.getloading
      },
      filteredQuestions () {
        return this.questions.slice(this.startIndex, this.endIndex)
      }
    },
    methods: {
      handle2020 () {
        if (
          !this.surveyAnswers[2020] === 20207 ||
          !this.surveyAnswers[2020] === 20208 ||
          !this.surveyAnswers[2020] === 20209
        ) {
          this.surveyAnswers[2025] = null
          this.answerMap.delete(2025)
        }
      },
      handle1040 () {
        if (this.surveyAnswers[1040] !== '1') {
          this.surveyAnswers[2020] = null
          this.surveyAnswers[2025] = null
          this.answerMap.delete(2020)
          this.answerMap.delete(2025)
        }
      },
      handle3700 () {
        if (this.surveyAnswers[3700] === '0') {
          this.surveyAnswers[3701] = null
          this.surveyAnswers[3702] = null
          this.surveyAnswers[3703] = null
          this.surveyAnswers[37031] = null
          this.surveyAnswers[37032] = null
          this.surveyAnswers[37033] = null
          this.surveyAnswers[37034] = null
          this.surveyAnswers[37035] = null
          this.surveyAnswers[37036] = null
          this.surveyAnswers[370361] = null
          this.answerMap.delete(3701)
          this.answerMap.delete(3702)
          this.answerMap.delete(3703)
          this.answerMap.delete(37031)
          this.answerMap.delete(37032)
          this.answerMap.delete(37033)
          this.answerMap.delete(37034)
          this.answerMap.delete(37035)
          this.answerMap.delete(37036)
          this.answerMap.delete(370361)
        }
        this.showAnswers()
      },
      handle1130 () {
        let answer = this.surveyAnswers[1130]
        if (answer === 11301) {
          this.surveyAnswers[1980] = null
          this.surveyAnswers[1990] = null
          this.surveyAnswers[1135] = null
          this.surveyAnswers[2000] = null
          this.answerMap.delete(1980)
          this.answerMap.delete(1990)
          this.answerMap.delete(1135)
          this.answerMap.delete(2000)
        }
        if (answer === 11302) {
          this.surveyAnswers[1990] = null
          this.surveyAnswers[1135] = null
          this.surveyAnswers[2000] = null
          this.answerMap.delete(1990)
          this.answerMap.delete(1135)
          this.answerMap.delete(2000)
        }
        if (answer === 11303) {
          this.surveyAnswers[1980] = null
          this.surveyAnswers[1990] = null
          this.surveyAnswers[2000] = null
          this.answerMap.delete(1980)
          this.answerMap.delete(1990)
          this.answerMap.delete(2000)
        }
        if (answer === 11305) {
          this.surveyAnswers[1980] = null
          this.surveyAnswers[1135] = null
          this.answerMap.delete(1980)
          this.answerMap.delete(1135)
        }
        this.showAnswers()
      },
      handle1020 () {
        if (this.surveyAnswers[1020] === '1') {
          this.answerMap.delete(1130)
          this.answerMap.delete(1135)
          this.answerMap.delete(1980)
          this.answerMap.delete(1990)
          this.answerMap.delete(2000)
          this.surveyAnswers[1990] = null
          this.surveyAnswers[2000] = null
          this.surveyAnswers[1130] = null
          this.surveyAnswers[1135] = null
          this.surveyAnswers[1980] = null
        } else {
          this.answerMap.delete(1021)
          this.answerMap.delete(1022)
          this.surveyAnswers[1021] = null
          this.surveyAnswers[1022] = null
        }
        this.showAnswers()
      },
      handle1120 () {
        if (this.surveyAnswers[1120] === '0') {
          this.surveyAnswers[1960] = null
          this.surveyAnswers[1961] = null
          this.answerMap.delete(1960)
          this.answerMap.delete(1961)
        }
        this.showAnswers()
      },
      handle1960 () {
        if (this.surveyAnswers[1960] === 19601) {
          this.surveyAnswers[1961] = null
          this.answerMap.delete(1961)
        }
        this.showAnswers()
      },
      handle1260 () {
        if (this.surveyAnswers[1260] === '1') {
          this.answerMap.delete(1261)
          this.answerMap.delete(1262)
          this.answerMap.delete(1263)
          this.answerMap.delete(1264)
          this.answerMap.delete(1265)
          this.answerMap.delete(1266)
          this.surveyAnswers[1261] = null
          this.surveyAnswers[1262] = null
          this.surveyAnswers[1263] = null
          this.surveyAnswers[1264] = null
          this.surveyAnswers[1265] = null
          this.surveyAnswers[1266] = null
          this.showAnswers()
        }
      },
      handle1261 () {
        if (this.surveyAnswers[1261] === '0') {
          this.answerMap.delete(1262)
          this.answerMap.delete(1263)
          this.answerMap.delete(1264)
          this.answerMap.delete(1265)
          this.answerMap.delete(1266)
          this.surveyAnswers[1262] = null
          this.surveyAnswers[1263] = null
          this.surveyAnswers[1264] = null
          this.surveyAnswers[1265] = null
          this.surveyAnswers[1266] = null
          this.showAnswers()
        }
      },
      handle1262 () {
        if (this.surveyAnswers[1262] !== 12622) {
          this.answerMap.delete(1263)
          this.answerMap.delete(1264)
          this.answerMap.delete(1265)
          this.surveyAnswers[1263] = null
          this.surveyAnswers[1264] = null
          this.surveyAnswers[1265] = null
        }
        if (this.surveyAnswers[1262] !== 1263) {
          this.answerMap.delete(1266)
          this.surveyAnswers[1266] = null
        }
        this.showAnswers()
      },
      handle3782 () {
        if (this.surveyAnswers[3782] === '0') {
          this.surveyAnswers[3783] = null
          this.surveyAnswers[3784] = null
          this.surveyAnswers[3785] = null
          this.answerMap.delete(3783)
          this.answerMap.delete(3784)
          this.answerMap.delete(3785)
        }
      },
      handle377131 () {
        if (!this.surveyAnswers[377131].includes(3771311)) {
          this.surveyAnswers[37713111] = null
          this.answerMap.delete(37713111)
          this.showAnswers()
        }
      },
      handle3770 () {
        // if no, clear all child answers
        if (this.surveyAnswers[3770] === '0') {
          this.surveyAnswers[3771] = null
          this.surveyAnswers[37711] = null
          this.surveyAnswers[37712] = null
          this.surveyAnswers[37713] = null
          this.surveyAnswers[37714] = null
          this.surveyAnswers[37715] = null
          this.surveyAnswers[377121] = null
          this.surveyAnswers[3771211] = null
          this.surveyAnswers[3771212] = null
          this.surveyAnswers[3771213] = null
          this.surveyAnswers[3771214] = null
          this.surveyAnswers[377131] = null
          this.surveyAnswers[3771311] = null
          this.surveyAnswers[3771312] = null
          this.surveyAnswers[3771313] = null
          this.surveyAnswers[37713111] = null
          this.answerMap.delete(3771)
          this.answerMap.delete(37711)
          this.answerMap.delete(37712)
          this.answerMap.delete(37713)
          this.answerMap.delete(37714)
          this.answerMap.delete(37715)
          this.answerMap.delete(377121)
          this.answerMap.delete(3771211)
          this.answerMap.delete(3771212)
          this.answerMap.delete(3771213)
          this.answerMap.delete(3771214)
          this.answerMap.delete(377131)
          this.answerMap.delete(3771311)
          this.answerMap.delete(3771312)
          this.answerMap.delete(3771313)
          this.answerMap.delete(37713111)
        }
        this.showAnswers()
      },
      handle3771 () {
        // if no scuba diving, clear child answers
        if (!this.surveyAnswers[3771].includes(37712)) {
          this.surveyAnswers[377121] = null
          this.answerMap.delete(377121)
        }
        // if no rock climbing, clear child answers
        if (!this.surveyAnswers[3771].includes(37713)) {
          this.surveyAnswers[377131] = null
          this.surveyAnswers[37713111] = null
          this.answerMap.delete(377131)
          this.answerMap.delete(37713111)
        }
        this.surveyAnswers[3771].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3771',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
        })
        this.answerMap.forEach((value, key) => {
          let keyString = key.toString()
          if (
            keyString.startsWith('3771') &&
            keyString.length === 5 &&
            !this.surveyAnswers[3771].includes(key)
          ) {
            this.answerMap.delete(key)
          }
        })
        this.showAnswers()
      },
      handle37308162 () {
        // if no seizures, clear child answers
        if (
          !this.surveyAnswers[37308162].includes(373081621) ||
          !this.surveyAnswers[37308162].includes(373081622)
        ) {
          this.surveyAnswers[3730816211] = null
          this.answerMap.delete(3730816211)
        }
        // if not other, clear child answers
        if (!this.surveyAnswers[37308162].includes(373081623)) {
          this.surveyAnswers[3730816231] = null
          this.answerMap.delete(3730816231)
        }
        this.surveyAnswers[37308162].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
        })
        this.answerMap.forEach((value, key) => {
          if (
            key.toString().startsWith('37308162') &&
            !this.surveyAnswers[37308162].includes(key)
          ) {
            this.answerMap.delete(key)
          }
        })
        this.showAnswers()
      },
      handle373081 () {
        if (!this.surveyAnswers[373081].includes(3730818)) {
          this.surveyAnswers[37308181] = null
          console.log('REMOVING 37308181')
          this.answerMap.delete(37308181)
        }
        if (!this.surveyAnswers[373081].includes(3730816)) {
          this.surveyAnswers[37308162] = null
          this.surveyAnswers[3730816231] = null
          this.surveyAnswers[3730816211] = null
          this.answerMap.delete(37308162)
          this.answerMap.delete(3730816231)
          this.answerMap.delete(3730816211)
        }
        this.answerMap.forEach((value, key) => {
          if (
            key.toString().startsWith('373081') &&
            key !== 37308181 &&
            key !== 3730816211 &&
            !this.surveyAnswers[373081].includes(key)
          ) {
            this.answerMap.delete(key)
          }
        })
        this.surveyAnswers[373081].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
        })
        this.showAnswers()
      },
      handle373031 () {
        if (!this.surveyAnswers[373031].includes(3730311)) {
          this.surveyAnswers[37303111] = null
          this.answerMap.delete(37303111)
        }
        if (!this.surveyAnswers[373031].includes(3730312)) {
          this.surveyAnswers[37303121] = null
          this.answerMap.delete(37303121)
        }
        this.surveyAnswers[373031].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
        })
        this.answerMap.forEach((value, key) => {
          if (
            key.toString().startsWith('373031') &&
            !this.surveyAnswers[373031].includes(key)
          ) {
            this.answerMap.delete(key)
          }
        })
        this.showAnswers()
      },
      handle3703 () {
        if (
          this.surveyAnswers[3703] &&
          !this.surveyAnswers[3703].includes(37036)
        ) {
          this.surveyAnswers[370361] = null
        }
        this.surveyAnswers[3703].forEach(answer => {
          if (!isNaN(answer)) {
            this.answerMap.set(answer, {
              questionId: '3703',
              answerOptionId: answer.toString(),
              answerValue: '1'
            })
          }
          // if answerMap has an answer that starts with 3703 but is not in the surveyAnswers[3703] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('3703') &&
              !this.surveyAnswers[3703].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
        this.showAnswers()
      },
      scroll () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      cancel () {
        this.$router.push({ path: '/' })
      },
      handle3805 () {
        if (!this.drivingViolations.includes(this.surveyAnswers[3805])) {
          this.surveyAnswers[3815] = null
          this.answerMap.delete(3815)
        }
      },
      handle3800 () {
        if (!this.drivingViolations.includes(this.surveyAnswers[3800])) {
          this.surveyAnswers[3810] = null
          this.answerMap.delete(3810)
        }
      },
      handle1170 () {
        if (!this.drivingViolations.includes(this.surveyAnswers[1170])) {
          this.surveyAnswers[1175] = null
          this.answerMap.delete(1175)
        }
      },
      handle373011111 () {
        if (!this.surveyAnswers[373011111].includes(3730111113)) {
          this.surveyAnswers[37301111131] = null
          this.answerMap.delete(37301111131)
        }
        this.surveyAnswers[373011111].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          // if answerMap has an answer that starts with 373011 but is not in the surveyAnswers[373011] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('373011111') &&
              !this.surveyAnswers[373011111].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
        this.showAnswers()
      },
      handle3730111116 () {
        const surveyAnswers = this.surveyAnswers[3730111116]
        if (!surveyAnswers.includes(37301111136)) {
          this.surveyAnswers[373011111316] = null
          this.answerMap.delete(373011111316)
        }
        const answerMap = this.answerMap
        const keys = [37301111116, 37301111126, 37301111136]

        surveyAnswers.forEach(answer => {
          answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
        })

        answerMap.forEach((value, key) => {
          if (keys.includes(key) && !surveyAnswers.includes(key)) {
            answerMap.delete(key)
          }
        })

        this.showAnswers()
      },
      handle373011 () {
        if (!this.surveyAnswers[373011].includes(3730111)) {
          this.surveyAnswers[37301111] = null
          this.surveyAnswers[37301112] = null
          this.surveyAnswers[37301113] = null
          this.surveyAnswers[373011112] = null
          this.surveyAnswers[373011111] = null
          this.surveyAnswers[3730111111] = null
          this.surveyAnswers[3730111112] = null
          this.surveyAnswers[3730111113] = null
          this.surveyAnswers[37301111131] = null
          this.surveyAnswers[373011113] = null
          this.answerMap.delete(37301111)
          this.answerMap.delete(37301112)
          this.answerMap.delete(37301113)
          this.answerMap.delete(373011112)
          this.answerMap.delete(373011111)
          this.answerMap.delete(3730111111)
          this.answerMap.delete(3730111112)
          this.answerMap.delete(3730111113)
          this.answerMap.delete(37301111131)
          this.answerMap.delete(373011113)
        }
        if (!this.surveyAnswers[373011].includes(3730116)) {
          this.surveyAnswers[373011116] = null
          this.surveyAnswers[3730111126] = null
          this.surveyAnswers[3730111136] = null
          this.surveyAnswers[3730111116] = null
          this.surveyAnswers[37301111116] = null
          this.surveyAnswers[37301111126] = null
          this.surveyAnswers[37301111136] = null
          this.surveyAnswers[373011111316] = null
          this.answerMap.delete[373011116] = null
          this.answerMap.delete[3730111126] = null
          this.answerMap.delete[3730111136] = null
          this.answerMap.delete[3730111116] = null
          this.answerMap.delete[37301111116] = null
          this.answerMap.delete[37301111126] = null
          this.answerMap.delete[37301111136] = null
          this.answerMap.delete[373011111316] = null
        }
        this.surveyAnswers[373011].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          // if answerMap has an answer that starts with 373011 but is not in the surveyAnswers[373011] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('373011') &&
              !this.surveyAnswers[373011].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
      },
      handle373011116 () {
        if (this.surveyAnswers[373011116] !== '1') {
          this.surveyAnswers[3730111116] = null
          this.surveyAnswers[37301111116] = null
          this.surveyAnswers[37301111126] = null
          this.surveyAnswers[37301111136] = null
          this.surveyAnswers[373011111316] = null
          this.answerMap.delete(3730111116)
          this.answerMap.delete(37301111116)
          this.answerMap.delete(37301111126)
          this.answerMap.delete(37301111136)
          this.answerMap.delete(373011111316)
        }
        this.showAnswers()
      },
      // Major Depression
      handle37301111 () {
        if (this.surveyAnswers[37301111] !== '1') {
          this.surveyAnswers[373011111] = null
          this.surveyAnswers[3730111111] = null
          this.surveyAnswers[3730111112] = null
          this.surveyAnswers[3730111113] = null
          this.surveyAnswers[37301111131] = null
          this.answerMap.delete(373011111)
          this.answerMap.delete(3730111111)
          this.answerMap.delete(3730111112)
          this.answerMap.delete(3730111113)
          this.answerMap.delete(37301111131)
        }
        this.surveyAnswers[373011].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          // if answerMap has an answer that starts with 373011 but is not in the surveyAnswers[373011] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('373011') &&
              !this.surveyAnswers[373011].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
      },
      answerText (questionID, aID) {
        // find question in filteredQuestions
        let question = this.filteredQuestions.find(
          question => question.questionID === questionID
        )
        // find answer in question
        let answer = question.answers.find(
          answer => answer.answer_option_id === aID
        )
        // return answer text
        return answer.answer_text
      },
      onSelectedItem (selectedItem) {
        console.log('selectedItem', selectedItem)
      },
      handle372011 () {
        if (this.surveyAnswers[372011].length === 0) {
          this.answerMap.delete(3720111)
          this.answerMap.delete(3720112)
          this.answerMap.delete(3720113)
          this.answerMap.delete(3720114)
          this.answerMap.delete(3720115)
          this.answerMap.delete(3720116)
          this.answerMap.delete(3720117)
          this.answerMap.delete(3720118)
          this.answerMap.delete(3720119)
          this.answerMap.delete(37201110)
          this.answerMap.delete(37201120)
          this.answerMap.delete(37201121)
          this.answerMap.delete(37201211)
          this.answerMap.delete(3720121)
          this.surveyAnswers[3720121] = null
          this.surveyAnswers[37201211] = null
        }
        this.surveyAnswers[372011].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3720',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          // if answerMap has an answer that starts with 372011 but is not in the surveyAnswers[372011] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('372011') &&
              !this.surveyAnswers[372011].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
        this.showAnswers()
      },
      handle372021 () {
        if (this.surveyAnswers[372021].length === 0) {
          this.answerMap.delete(3720211)
          this.answerMap.delete(3720212)
          this.answerMap.delete(3720213)
          this.answerMap.delete(3720214)
          this.answerMap.delete(3720215)
          this.answerMap.delete(3720216)
          this.answerMap.delete(3720217)
          this.answerMap.delete(3720218)
          this.answerMap.delete(3720219)
          this.answerMap.delete(37202110)
          this.answerMap.delete(37202111)
          this.answerMap.delete(37202112)
          this.answerMap.delete(372021121)
          this.surveyAnswers[372021121] = null
        }
        this.surveyAnswers[372021].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3720',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          // if answerMap has an answer that starts with 372021 but is not in the surveyAnswers[372021] array, remove it
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('372021') &&
              !this.surveyAnswers[372021].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
        this.showAnswers()
      },
      handle3730 () {
        const toReset = [
          373011,
          3730111,
          37301111,
          373011111,
          37301111131,
          373011112,
          373011113,
          373011116,
          373011126,
          373011136,
          3730111116,
          373011111316,
          373021,
          3730211,
          3730212,
          373081,
          37308162,
          37308181,
          3730816231,
          3730816211,
          3730101,
          37300111,
          37300112
        ]
        if (this.surveyAnswers[3730].length === 0) return
        if (this.surveyAnswers[3730].includes(373012)) {
          this.surveyAnswers[3730] = [373012]
          toReset.forEach(id => {
            this.surveyAnswers[id] = null
            this.answerMap.delete(id)
          })
        } else {
          if (!this.surveyAnswers[3730].includes(37301)) {
            toReset.slice(0, 11).forEach(id => {
              this.surveyAnswers[id] = null
              this.answerMap.delete(id)
            })
          }
          if (!this.surveyAnswers[3730].includes(37302)) {
            toReset.slice(11, 14).forEach(id => {
              this.surveyAnswers[id] = null
              this.answerMap.delete(id)
            })
          }
        }
        this.surveyAnswers[3730].forEach(answer => {
          this.answerMap.set(answer, {
            questionId: '3730',
            answerOptionId: answer.toString(),
            answerValue: '1'
          })
          this.answerMap.forEach((value, key) => {
            if (
              key.toString().startsWith('3730') &&
              !this.surveyAnswers[3730].includes(key)
            ) {
              this.answerMap.delete(key)
            }
          })
        })
      },
      handle3720 () {
        if (this.surveyAnswers[3720].includes(37203)) {
          console.log('handle case 1')
          this.surveyAnswers[3720] = [37203]
          this.surveyAnswers[372011] = null
          this.surveyAnswers[372021] = null
          let question = this.filteredQuestions.find(
            question => question.questionID === 3720
          )
          let answers = question.answers
          let aIDArr = []
          answers.forEach(answer => {
            aIDArr.push(answer.answer_option_id)
          })
          console.log('AID ARR', aIDArr)
          this.answerMap.set(37203, {
            questionId: '3720',
            answerOptionId: '37203',
            answerValue: '1'
          })
          aIDArr.forEach(aID => {
            if (aID !== 37203) {
              this.answerMap.delete(aID)
            }
          })
        } else if (
          this.surveyAnswers[3720].length === 1 &&
          this.surveyAnswers[3720].includes(37201)
        ) {
          console.log('handle case 2')
          this.answerMap.delete(37202)
          this.answerMap.delete(37203)
          this.answerMap.delete(37204)
          this.answerMap.set(37201, {
            questionId: '3720',
            answerOptionId: '37201',
            answerValue: '1'
          })
        } else if (
          this.surveyAnswers[3720].length === 1 &&
          this.surveyAnswers[3720].includes(37202)
        ) {
          console.log('handle case 3')
          this.answerMap.delete(37201)
          this.answerMap.delete(37203)
          this.answerMap.delete(37204)
          this.answerMap.set(37202, {
            questionId: '3720',
            answerOptionId: '37202',
            answerValue: '1'
          })
        } else if (
          this.surveyAnswers[3720].length === 1 &&
          this.surveyAnswers[3720].includes(37204)
        ) {
          console.log('handle case 4')
          this.answerMap.delete(37201)
          this.answerMap.delete(37202)
          this.answerMap.delete(37203)
          this.answerMap.set(37204, {
            questionId: '3720',
            answerOptionId: '37204',
            answerValue: '1'
          })
        } else if (
          this.surveyAnswers[3720].length > 1 &&
          this.surveyAnswers[3720].includes(37201) &&
          this.surveyAnswers[3720].includes(37202)
        ) {
          console.log('handle case 6')
          this.answerMap.delete(37203)
          this.answerMap.set(37201, {
            questionId: '3720',
            answerOptionId: '37201',
            answerValue: '1'
          })
          this.answerMap.set(37202, {
            questionId: '3720',
            answerOptionId: '37202',
            answerValue: '1'
          })
        } else if (
          this.surveyAnswers[3720].length > 1 &&
          this.surveyAnswers[3720].includes(37201) &&
          this.surveyAnswers[3720].includes(37204)
        ) {
          console.log('handle case 7')
          this.answerMap.delete(37203)
          this.answerMap.set(37201, {
            questionId: '3720',
            answerOptionId: '37201',
            answerValue: '1'
          })
          this.answerMap.set(37204, {
            questionId: '3720',
            answerOptionId: '37204',
            answerValue: '1'
          })
        }
        this.showAnswers()
      },
      async submitAppQa () {
        let complete = await this.$refs.observer.validate()
        let valid = await this.$refs.form.validate()
        if (!valid || !complete) {
          return
        }
        this.scroll()
        console.log('Survey Answers', this.surveyAnswers)
        let answers = Array.from(this.answerMap.values())
        this.$emit('updateanswers', answers)
        this.$store.dispatch('USTermGetForms', { stage: 1 }).then(() => {
          this.nextQuestion('uwsurvey')
        })
      },
      formatHeight (question) {
        let height = ''
        let feet = this.surveyAnswers.feet
        let inches = this.surveyAnswers.inches
        if (feet && inches) {
          if (inches < 10) {
            inches = '0' + inches
          }
          height = `${feet}.${inches}`
          this.surveyAnswers[1002] = height
          this.answerMap.set(question.questionID, {
            questionId: question.questionID.toString(),
            answerValue: this.surveyAnswers[1002].toString()
          })
        }
      },
      showAnswers () {
        console.log(this.surveyAnswers)
        console.log(Array.from(this.answerMap.values()))
        console.log(this.answerMap)
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls .v-radio > .v-label {
  font-size: 1.2rem !important;
  margin-bottom: 0 !important;
}

::v-deep .v-list-item__title {
  white-space: normal !important;
}

::v-deep .v-select__selection--comma {
  white-space: normal !important;
}

p {
  font-size: 1.2rem !important;
}
</style>
