var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer"},[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" Beneficiaries ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.beneficiaries,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("My Beneficiaries")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 font-weight-bold",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Beneficiary ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h3 primary--text"},[_vm._v("Add Beneficiary")])]),_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{
                              required: true,
                              regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                              max: 20
                            },"name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"First name"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary firstName',
                                  49,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.firstName),callback:function ($$v) {_vm.$set(_vm.editedItem, "firstName", $$v)},expression:"editedItem.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{
                              required: true,
                              regex: /^[a-zA-Z\ -]+$/,
                              max: 30
                            },"name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Last Name"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary lastName',
                                  50,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.lastName),callback:function ($$v) {_vm.$set(_vm.editedItem, "lastName", $$v)},expression:"editedItem.lastName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Relationship"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","label":"Relationship to You","items":_vm.relationships,"error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary relationshipToYou',
                                  51,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.relationship),callback:function ($$v) {_vm.$set(_vm.editedItem, "relationship", $$v)},expression:"editedItem.relationship"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',[_vm._v("Date of birth")])]),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.daysavailable,"label":"Day","outlined":"","color":"primary","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary dobDay',
                                  52,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.bday),callback:function ($$v) {_vm.$set(_vm.editedItem, "bday", $$v)},expression:"editedItem.bday"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.monthsavailable,"label":"Month","outlined":"","color":"primary","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary dobMonth',
                                  53,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.bmonth),callback:function ($$v) {_vm.$set(_vm.editedItem, "bmonth", $$v)},expression:"editedItem.bmonth"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.yearsavailable,"label":"Year","outlined":"","color":"primary","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary dobYear',
                                  54,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.byear),callback:function ($$v) {_vm.$set(_vm.editedItem, "byear", $$v)},expression:"editedItem.byear"}})]}}],null,true)})],1),(_vm.invalidBirthday)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"red--text font-weight-bold text-center"},[_vm._v(" Please enter a valid date. ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:1|max_value:100","name":"Percentage"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"outlined":"","error-messages":errors,"label":"% Share"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                  'Beneficiary sharePercentage',
                                  55,
                                  'Afficiency Survey v1'
                                )}},model:{value:(_vm.editedItem.percentage),callback:function ($$v) {_vm.$set(_vm.editedItem, "percentage", $$v)},expression:"editedItem.percentage"}})]}}],null,true)})],1)],1),(
                          _vm.editedItem.relationship === 'Parent' ||
                            _vm.editedItem.relationship === 'Sibling'
                        )?_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"*I confirm that this beneficiary would be financially impacted by my death."},on:{"click":function($event){return _vm.clickedFieldGlobal(
                                'Beneficiary financialImpactCheckbox',
                                56,
                                'Afficiency Survey v1'
                              )}},model:{value:(_vm.editedItem.financialImpactCheckbox),callback:function ($$v) {_vm.$set(_vm.editedItem, "financialImpactCheckbox", $$v)},expression:"editedItem.financialImpactCheckbox"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"secondary white--text font-weight-bold",on:{"click":function($event){_vm.close();
                              _vm.clickedFieldGlobal(
                                'Beneficiary cncelSaveBeneficiary',
                                57,
                                'Afficiency Survey v1'
                              );}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"primary white--text font-weight-bold",attrs:{"disabled":invalid ||
                                (_vm.editedItem.relationship === 'Parent' &&
                                  !_vm.editedItem.financialImpactCheckbox) ||
                                (_vm.editedItem.relationship === 'Sibling' &&
                                  !_vm.editedItem.financialImpactCheckbox) ||
                                _vm.invalidBirthday},on:{"click":function($event){_vm.save();
                              _vm.clickedFieldGlobal(
                                'Beneficiary saveBeneficiary',
                                58,
                                'Afficiency Survey v1'
                              );}}},[_vm._v(" Save ")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.closeDelete();
                      _vm.clickedFieldGlobal(
                        'Beneficiary cncelDeleteBeneficiary',
                        59,
                        'Afficiency Survey v1'
                      );}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteItemConfirm();
                      _vm.clickedFieldGlobal(
                        'Beneficiary confirmDeleteBeneficiary',
                        60,
                        'Afficiency Survey v1'
                      );}}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.editItem(item);
              _vm.clickedFieldGlobal(
                'Beneficiary editItem',
                61,
                'Afficiency Survey v1'
              );}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.deleteItem(item);
              _vm.clickedFieldGlobal(
                'Beneficiary deleteItem',
                62,
                'Afficiency Survey v1'
              );}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"d-flex justify-center font-weight-bold"},[_vm._v(" Total Percentage: "+_vm._s(_vm.totalPercentage)+" ")])],1),(_vm.afficiencyBeneficiaries.length > 0 && _vm.totalPercentage !== 100)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" Total Percentage of Beneficiaries must be 100 ")])])],1):_vm._e()],1),_c('validation-observer',{ref:"observer2",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.states.includes(_vm.user.state))?_c('div',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{staticClass:"text-h3 primary--text font-weight-bold text-center"},[_vm._v(" Third Party Notification – Protect Against Unintended Lapse ")])],1),_c('v-row',[_c('v-col',{staticClass:"d-inline-flex align-center justify-center"},[(_vm.user.state === 'FL')?_c('span',[_vm._v("You have the right to designate someone to receive a copy of notices of cancellation or lapse of your policy. This designation does not constitute the acceptance of any liability on the part of this person or SBLI for services provided to you.")]):_c('span',{staticClass:"text-center"},[_vm._v("You have the right to designate someone to receive a copy of notices of cancellation or lapse of your policy. This designation does not constitute the acceptance of any liability on the part of this person or SBLI for services provided to you. "),_c('br'),_c('br'),_vm._v(" Do wish to designate a third party?")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center text-center"},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.lapseContact),callback:function ($$v) {_vm.lapseContact=$$v},expression:"lapseContact"}},[_c('v-radio',{attrs:{"label":"Yes","value":true},on:{"click":function($event){return _vm.clickedFieldGlobal(
                    'Beneficiary designateThrdPrtyY',
                    63,
                    'Afficiency Survey v1'
                  )}}}),_c('v-radio',{attrs:{"label":"No","value":false},on:{"click":function($event){return _vm.clickedFieldGlobal(
                    'Beneficiary designateThrdPrtyN',
                    64,
                    'Afficiency Survey v1'
                  )}}})],1)],1)],1),(_vm.lapseContact)?_c('v-row',[_c('v-col',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Designee First Name","rules":{
                        required: true,
                        regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                        max: 20
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Designee First Name","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeFirstName',
                            65,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.firstname),callback:function ($$v) {_vm.$set(_vm.thirdParty, "firstname", $$v)},expression:"thirdParty.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Designee Last Name","rules":{
                        required: true,
                        regex: /^[a-zA-Z\ -]+$/,
                        max: 30
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Designee Last Name","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeLastName',
                            66,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.lastname),callback:function ($$v) {_vm.$set(_vm.thirdParty, "lastname", $$v)},expression:"thirdParty.lastname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vuetify-google-autocomplete',{ref:"address",attrs:{"id":"map","classname":"form-control","color":"primary","outlined":"","placeholder":"Home Address","country":"us"},on:{"placechanged":_vm.getAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                          'Beneficiary designeeAutocompAddress',
                          67,
                          'Afficiency Survey v1'
                        )}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Address","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeStreet',
                            68,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.street),callback:function ($$v) {_vm.$set(_vm.thirdParty, "street", $$v)},expression:"thirdParty.street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"City","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeCity',
                            69,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.city),callback:function ($$v) {_vm.$set(_vm.thirdParty, "city", $$v)},expression:"thirdParty.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"State","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeState',
                            70,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.state),callback:function ($$v) {_vm.$set(_vm.thirdParty, "state", $$v)},expression:"thirdParty.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Zip Code","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeZip',
                            71,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.zip),callback:function ($$v) {_vm.$set(_vm.thirdParty, "zip", $$v)},expression:"thirdParty.zip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"E-mail Address","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary designeeEmail',
                            72,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.email),callback:function ($$v) {_vm.$set(_vm.thirdParty, "email", $$v)},expression:"thirdParty.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Day Phone","rules":{ required: true, min: 10, max: 10 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"label":"Day Phone","color":"primary","outlined":"","error-messages":errors,"required":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'Beneficiary dayPhone',
                            73,
                            'Afficiency Survey v1'
                          )}},model:{value:(_vm.thirdParty.phone),callback:function ($$v) {_vm.$set(_vm.thirdParty, "phone", $$v)},expression:"thirdParty.phone"}})]}}],null,true)})],1)],1)],1)],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"orange white--text","to":"/"},on:{"click":function($event){return _vm.clickedFieldGlobal(
              'Beneficiary cnclSubmitBeneficiary',
              74,
              'Afficiency Survey v1'
            )}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-large":"","color":"primary","disabled":_vm.afficiencyBeneficiaries.length < 1 ||
              _vm.afficiencyBeneficiaries.length > 10 ||
              _vm.totalPercentage !== 100 ||
              invalid},on:{"click":function($event){_vm.submitBene();
            _vm.clickedFieldGlobal(
              'Beneficiary submitBeneficiary',
              75,
              'Afficiency Survey v1'
            );}}},[_vm._v(" Continue ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }