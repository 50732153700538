<template>
  <div>
    <validation-observer
      v-slot="{ invalid }"
      ref="observer"
    >
      <v-card
        v-if="!loading"
        min-height="300"
        elevation="0"
      >
        <div
          class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
        >
          Personal Information
        </div>
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="8"
          >
            <p>Enter your name</p>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="First Name"
                  :rules="{
                    required: true,
                    regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                    max: 20
                  }"
                >
                  <v-text-field
                    v-model="user.firstName"
                    outlined
                    color="primary"
                    label="First Name"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal(
                        'PII first name',
                        29,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Middle Name"
                  rules="alpha"
                >
                  <v-text-field
                    v-model="user.middleName"
                    outlined
                    color="primary"
                    label="Middle Name"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal(
                        'PII middle name',
                        30,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Last Name"
                  :rules="{ required: true, regex: /^[a-zA-Z\ -]+$/, max: 30 }"
                >
                  <v-text-field
                    v-model="user.lastName"
                    outlined
                    color="primary"
                    label="Last Name"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal(
                        'PII last name',
                        31,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <p>Enter your ssn</p>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="SSN"
                  :rules="{ required: true, min: 9, max: 9 }"
                >
                  <v-text-field
                    v-model="user.ssn"
                    v-mask="'#########'"
                    outlined
                    color="primary"
                    label="SSN"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal('PII ssn', 32, 'Afficiency Survey v1')
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <p>Enter your email address</p>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="user.email"
                    outlined
                    color="primary"
                    label="Email"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal(
                        'PII email',
                        33,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <p>Enter your phone number</p>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone"
                  :rules="{ required: true, min: 10, max: 10 }"
                >
                  <v-text-field
                    v-model="user.phone"
                    v-mask="'##########'"
                    outlined
                    color="primary"
                    label="Phone"
                    :error-messages="errors"
                    @click="
                      clickedFieldGlobal(
                        'PII phone',
                        34,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>Home Address (No P.O. boxes)</p>
                <vuetify-google-autocomplete
                  id="map1"
                  ref="resaddress"
                  classname="form-control"
                  outlined
                  placeholder="Please type your address"
                  country="us"
                  @placechanged="getaddress"
                  @click="
                    clickedFieldGlobal(
                      'PII res address',
                      35,
                      'Afficiency Survey v1'
                    )
                  "
                >
                </vuetify-google-autocomplete>
                <validation-provider
                  v-slot="{ errors }"
                  name="resaddress"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.addressLine1"
                    :error-messages="errors"
                    label="Address"
                    color="primary"
                    outlined
                    @click="
                      clickedFieldGlobal(
                        'PII address',
                        36,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.city"
                    :error-messages="errors"
                    label="City"
                    color="primary"
                    outlined
                    @click="
                      clickedFieldGlobal('PII city', 37, 'Afficiency Survey v1')
                    "
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="state"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.state"
                    v-mask="'AA'"
                    :error-messages="errors"
                    label="State"
                    color="primary"
                    outlined
                    @click="
                      clickedFieldGlobal(
                        'PII state',
                        38,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="zipcode"
                  :rules="{ required: true, min: 5, max: 5 }"
                >
                  <v-text-field
                    v-model="user.zipCode"
                    v-mask="'#####'"
                    :error-messages="errors"
                    label="Zipcode"
                    color="primary"
                    outlined
                    @click="
                      clickedFieldGlobal(
                        'PII zipcode',
                        39,
                        'Afficiency Survey v1'
                      )
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-2 ma-2">
          <v-btn
            x-large
            class="orange white--text"
            :disabled="loading || existingApplication"
            @click="
              cancel();
              clickedFieldGlobal('PII back button', 40, 'Afficiency Survey v1')
            "
          >
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-large
            :disabled="invalid || loading"
            @click="
              next();
              clickedFieldGlobal(
                'PII continue button',
                41,
                'Afficiency Survey v1'
              )
            "
          >
            Continue
          </v-btn>
        </v-row>
      </v-card>
      <v-row
        v-if="loading"
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </validation-observer>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="PIIErrorDialog"
          transition="dialog-bottom-transition"
          width="auto"
          persistent
        >
          <v-card>
            <v-card-title class="justify-center font-weight-bold text-h5">
              Personal Information Error
            </v-card-title>
            <v-card-title
              class="justify-center text-center body primmary--text mt-4"
            >
              <v-row>
                <v-col>{{ PIIError }}</v-col>
              </v-row>
            </v-card-title>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      clearPIIError();
                      clickedFieldGlobal(
                        'PII errorCloseBtn',
                        42,
                        'Afficiency Survey v1'
                      )
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import EllipseProgress from '../../components/EllipseProgress.vue'
  export default {
    name: 'PII',
    components: {
      EllipseProgress
    },
    props: [
      'user',
      'nextQuestion',
      'cancel',
      'getaddress',
      'initApp',
      'savedQuote',
      'step'
    ],
    data () {
      return {
        PIIErrorDialog: false
      }
    },
    computed: {
      PIIError () {
        let error = ''
        if (this.$store.getters.getPIIError) {
          error = this.$store.getters.getPIIError
          if (this.step === 3 && !this.loading) {
            this.PIIErrorDialog = true
          }
        }
        return error
      },
      existingApplication () {
        let app = false
        if (this.$store.getters.getUSTermExistingApplication) {
          app = this.$store.getters.getUSTermExistingApplication
        }
        return app
      },
      loading () {
        return this.$store.getters.getloading
      },
      arcId () {
        return this.$store.getters.getArcId
      },
      error () {
        return this.$store.getters.getError
      }
    },
    mounted () {
      console.log(this.existingApplication)
    },
    methods: {
      clearPIIError () {
        this.$store.dispatch('setPIIError', '')
        this.$store.dispatch('clearError')
        this.PIIErrorDialog = false
      },
      scroll () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      async next () {
        this.scroll()
        if (this.savedQuote) {
          console.log('init with savedQuote')
          this.$store
            .dispatch('USTermInitApplication', {
              user: this.user,
              savedQuote: this.savedQuote
            })
            .then(() => {
              if (!this.PIIError) {
                this.$emit('next')
              }
            })
        } else {
          console.log('init without savedQuote')
          this.$store
            .dispatch('USTermInitApplication', { user: this.user })
            .then(() => {
              if (!this.PIIError) {
                this.$emit('next')
              }
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow: hidden !important;
}
</style>
