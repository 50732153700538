<template>
  <v-container>
    <v-card elevation="0">
      <validation-observer ref="observer">
        <p class="text-center font-weight-bold">
          Beneficiaries
        </p>
        <v-data-table
          :headers="headers"
          :items="beneficiaries"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>My Beneficiaries</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="700px"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add Beneficiary
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h3 primary--text">Add Beneficiary</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <validation-observer
                        v-slot="{ invalid }"
                        ref="observer"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                required: true,
                                regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                                max: 20
                              }"
                              name="First Name"
                            >
                              <v-text-field
                                v-model="editedItem.firstName"
                                outlined
                                :error-messages="errors"
                                label="First name"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary firstName',
                                    49,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                required: true,
                                regex: /^[a-zA-Z\ -]+$/,
                                max: 30
                              }"
                              name="Last Name"
                            >
                              <v-text-field
                                v-model="editedItem.lastName"
                                outlined
                                :error-messages="errors"
                                label="Last Name"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary lastName',
                                    50,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Relationship"
                            >
                              <v-select
                                v-model="editedItem.relationship"
                                outlined
                                label="Relationship to You"
                                :items="relationships"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary relationshipToYou',
                                    51,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            class="text-center"
                          >
                            <p>Date of birth</p>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Day"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="editedItem.bday"
                                :items="daysavailable"
                                label="Day"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary dobDay',
                                    52,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Month"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="editedItem.bmonth"
                                :items="monthsavailable"
                                label="Month"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary dobMonth',
                                    53,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Year"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="editedItem.byear"
                                :items="yearsavailable"
                                label="Year"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary dobYear',
                                    54,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="invalidBirthday"
                            cols="12"
                          >
                            <p class="red--text font-weight-bold text-center">
                              Please enter a valid date.
                            </p>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|min_value:1|max_value:100"
                              name="Percentage"
                            >
                              <v-text-field
                                v-model="editedItem.percentage"
                                v-mask="'###'"
                                outlined
                                :error-messages="errors"
                                label="% Share"
                                @click="
                                  clickedFieldGlobal(
                                    'Beneficiary sharePercentage',
                                    55,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="
                            editedItem.relationship === 'Parent' ||
                              editedItem.relationship === 'Sibling'
                          "
                        >
                          <v-col>
                            <v-checkbox
                              v-model="editedItem.financialImpactCheckbox"
                              label="*I confirm that this beneficiary would be financially impacted by my death."
                              @click="
                                clickedFieldGlobal(
                                  'Beneficiary financialImpactCheckbox',
                                  56,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-space-between">
                            <v-btn
                              class="secondary white--text font-weight-bold"
                              @click="
                                close();
                                clickedFieldGlobal(
                                  'Beneficiary cncelSaveBeneficiary',
                                  57,
                                  'Afficiency Survey v1'
                                );
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              class="primary white--text font-weight-bold"
                              :disabled="
                                invalid ||
                                  (editedItem.relationship === 'Parent' &&
                                    !editedItem.financialImpactCheckbox) ||
                                  (editedItem.relationship === 'Sibling' &&
                                    !editedItem.financialImpactCheckbox) ||
                                  invalidBirthday
                              "
                              @click="
                                save();
                                clickedFieldGlobal(
                                  'Beneficiary saveBeneficiary',
                                  58,
                                  'Afficiency Survey v1'
                                );
                              "
                            >
                              Save
                            </v-btn>
                          </v-col>
                        </v-row>
                      </validation-observer>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="
                        closeDelete();
                        clickedFieldGlobal(
                          'Beneficiary cncelDeleteBeneficiary',
                          59,
                          'Afficiency Survey v1'
                        );
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="
                        deleteItemConfirm();
                        clickedFieldGlobal(
                          'Beneficiary confirmDeleteBeneficiary',
                          60,
                          'Afficiency Survey v1'
                        );
                      "
                    >
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="
                editItem(item);
                clickedFieldGlobal(
                  'Beneficiary editItem',
                  61,
                  'Afficiency Survey v1'
                );
              "
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="
                deleteItem(item);
                clickedFieldGlobal(
                  'Beneficiary deleteItem',
                  62,
                  'Afficiency Survey v1'
                );
              "
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-row class="mt-6">
          <v-col class="d-flex justify-center font-weight-bold">
            Total Percentage: {{ totalPercentage }}
          </v-col>
        </v-row>
        <v-row
          v-if="afficiencyBeneficiaries.length > 0 && totalPercentage !== 100"
        >
          <v-col class="d-flex justify-center">
            <div class="red--text font-weight-bold">
              Total Percentage of Beneficiaries must be 100
            </div>
          </v-col>
        </v-row>
      </validation-observer>
      <validation-observer
        ref="observer2"
        v-slot="{ invalid }"
      >
        <div
          v-if="states.includes(user.state)"
          class="mt-4"
        >
          <v-row>
            <v-col class="text-h3 primary--text font-weight-bold text-center">
              Third Party Notification – Protect Against Unintended Lapse
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-inline-flex align-center justify-center">
              <span
                v-if="user.state === 'FL'"
              >You have the right to designate someone to receive a copy of
                notices of cancellation or lapse of your policy. This
                designation does not constitute the acceptance of any liability
                on the part of this person or SBLI for services provided to
                you.</span>
              <span
                v-else
                class="text-center"
              >You have the right to designate someone to receive a copy of
                notices of cancellation or lapse of your policy. This
                designation does not constitute the acceptance of any liability
                on the part of this person or SBLI for services provided to you.
                <br /><br />
                Do wish to designate a third party?</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center text-center">
              <v-radio-group
                v-model="lapseContact"
                row
                mandatory
              >
                <v-radio
                  label="Yes"
                  :value="true"
                  @click="
                    clickedFieldGlobal(
                      'Beneficiary designateThrdPrtyY',
                      63,
                      'Afficiency Survey v1'
                    )
                  "
                ></v-radio>
                <v-radio
                  label="No"
                  :value="false"
                  @click="
                    clickedFieldGlobal(
                      'Beneficiary designateThrdPrtyN',
                      64,
                      'Afficiency Survey v1'
                    )
                  "
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="lapseContact">
            <v-col>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Designee First Name"
                        :rules="{
                          required: true,
                          regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                          max: 20
                        }"
                      >
                        <v-text-field
                          v-model="thirdParty.firstname"
                          label="Designee First Name"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeFirstName',
                              65,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Designee Last Name"
                        :rules="{
                          required: true,
                          regex: /^[a-zA-Z\ -]+$/,
                          max: 30
                        }"
                      >
                        <v-text-field
                          v-model="thirdParty.lastname"
                          label="Designee Last Name"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeLastName',
                              66,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <vuetify-google-autocomplete
                        id="map"
                        ref="address"
                        classname="form-control"
                        color="primary"
                        outlined
                        placeholder="Home Address"
                        country="us"
                        @placechanged="getAddressData"
                        @click="
                          clickedFieldGlobal(
                            'Beneficiary designeeAutocompAddress',
                            67,
                            'Afficiency Survey v1'
                          )
                        "
                      >
                      </vuetify-google-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Street"
                        rules="required"
                      >
                        <v-text-field
                          v-model="thirdParty.street"
                          label="Address"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeStreet',
                              68,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <v-text-field
                          v-model="thirdParty.city"
                          label="City"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeCity',
                              69,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <v-text-field
                          v-model="thirdParty.state"
                          label="State"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeState',
                              70,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Zip"
                        rules="required"
                      >
                        <v-text-field
                          v-model="thirdParty.zip"
                          label="Zip Code"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeZip',
                              71,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="thirdParty.email"
                          label="E-mail Address"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary designeeEmail',
                              72,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Day Phone"
                        :rules="{ required: true, min: 10, max: 10 }"
                      >
                        <v-text-field
                          v-model="thirdParty.phone"
                          v-mask="'##########'"
                          label="Day Phone"
                          color="primary"
                          outlined
                          :error-messages="errors"
                          required
                          @click="
                            clickedFieldGlobal(
                              'Beneficiary dayPhone',
                              73,
                              'Afficiency Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn
            x-large
            color="orange white--text"
            to="/"
            @click="
              clickedFieldGlobal(
                'Beneficiary cnclSubmitBeneficiary',
                74,
                'Afficiency Survey v1'
              )
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            color="primary"
            :disabled="
              afficiencyBeneficiaries.length < 1 ||
                afficiencyBeneficiaries.length > 10 ||
                totalPercentage !== 100 ||
                invalid
            "
            @click="
              submitBene();
              clickedFieldGlobal(
                'Beneficiary submitBeneficiary',
                75,
                'Afficiency Survey v1'
              );
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'AfficiencyBeneficiary',
    props: ['user', 'beneficiaries'],
    data: () => ({
      bday: '',
      bmonth: '',
      byear: '',
      todaysDate: moment().format('YYYY-MM-DD'),
      minDate: moment()
        .subtract(75, 'years')
        .format('YYYY-MM-DD'),
      thirdParty: {
        firstname: '',
        lastname: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: ''
      },
      lapseContact: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'First Name',
          align: 'start',
          value: 'firstName'
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Relationship to You', value: 'relationship' },
        { text: 'Date of Birth', value: 'dob' },
        { text: '% Share', value: 'percentage' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        relationship: '',
        relationshipType: '',
        dob: '',
        bday: '',
        bmonth: '',
        byear: '',
        percentage: '',
        financialImpactCheckbox: false
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        relationship: '',
        relationshipType: '',
        dob: '',
        bday: '',
        bmonth: '',
        byear: '',
        percentage: '',
        financialImpactCheckbox: false
      },
      relationships: ['Spouse', 'Domestic Partner', 'Parent', 'Child', 'Sibling'],
      states: ['CA', 'CT', 'FL', 'ID', 'ME', 'NJ', 'RI', 'VT', 'UT', 'MT']
    }),
    computed: {
      invalidBirthday () {
        let user = this.editedItem
        if (user.bday && user.bmonth && user.byear) {
          let bday = user.bday.toString()
          let bmonth = user.bmonth.toString()
          let byear = user.byear.toString()
          let dob = `${bmonth}-${bday}-${byear}`
          let formatted = moment(dob, 'MMM-DD-YYYY').format('MM/DD/YYYY')
          let valid = moment(formatted, 'MM/DD/YYYY').isValid()
          let isFutureDate = moment(formatted, 'MM/DD/YYYY').isAfter(moment())
          if (!valid || isFutureDate) {
            console.log('****************date not valid')
            return true
          }
          return false
        }
        return false
      },
      yearsavailable () {
        let maxyear = Number(moment().format('YYYY'))
        let startingyear = maxyear - 120
        let years = []
        for (let i = startingyear; i <= maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      formattedDob () {
        let date = ''
        if (
          this.editedItem.bday &&
          this.editedItem.bmonth &&
          this.editedItem.byear
        ) {
          date = moment(
            `${this.editedItem.bmonth} ${this.editedItem.bday}, ${this.editedItem.byear}`,
            'MMM DD, YYYY'
          ).format('MM-DD-YYYY')
          this.editedItem.dob = date
          console.log(date)
        }
        return date
      },
      designee () {
        return {
          firstName: this.thirdParty.firstname,
          lastName: this.thirdParty.lastname,
          address1: this.thirdParty.street,
          city: this.thirdParty.city,
          state: this.thirdParty.state,
          zip: this.thirdParty.zip,
          email: this.thirdParty.email,
          phone: this.thirdParty.phone.replaceAll('-', '')
        }
      },
      afficiencyBeneficiaries () {
        return this.$store.getters.getAfficiencyBeneficiaries
      },
      totalPercentage () {
        // return total of each beneficiary percentage
        return this.beneficiaries.reduce((total, item) => {
          return total + parseInt(item.percentage)
        }, 0)
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
    methods: {
      submitBene () {
        let beneficiaries = this.afficiencyBeneficiaries.map(bene => {
          return {
            firstName: bene.firstName,
            lastName: bene.lastName,
            relationship: bene.relationship,
            relationshipType: bene.relationshipType,
            dob: bene.dob,
            percentage: parseInt(bene.percentage)
          }
        })
        if (this.lapseContact) {
          this.$store.dispatch('USTermSubmitBene', {
            beneficiaries: beneficiaries,
            designee: this.designee
          })
        } else {
          this.$store.dispatch('USTermSubmitBene', {
            beneficiaries: beneficiaries
          })
        }
        this.next()
      },
      next () {
        this.$emit('next')
      },
      cancel () {
        this.$emit('cancel')
      },
      getAddressData (addressData, placeResultData, id) {
        this.thirdParty.street = addressData.name
        this.thirdParty.city = addressData.locality
        this.thirdParty.zip = addressData.postal_code
        this.thirdParty.state = addressData.administrative_area_level_1
        console.log(this.thirdPartyData)
      },
      editItem (item) {
        this.editedIndex = this.beneficiaries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.beneficiaries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.beneficiaries.splice(this.editedIndex, 1)
        this.$store.dispatch('updateAfficiencyBeneficaries', this.beneficiaries)
        console.log(this.beneficiaries)
        console.log(this.afficiencyBeneficiaries)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.editItem.dob = this.formattedDob
        let relationship = this.editedItem.relationship
        relationship === 'Spouse'
          ? Object.assign(this.editedItem, { relationshipType: 'Spouse' })
          : relationship === 'Child'
            ? Object.assign(this.editedItem, { relationshipType: 'Child' })
            : Object.assign(this.editedItem, {
              relationshipType: 'OtherNamedIndividual'
            })
        if (this.editedIndex > -1) {
          Object.assign(this.beneficiaries[this.editedIndex], this.editedItem)
        } else {
          this.beneficiaries.push(this.editedItem)
        }
        this.$store.dispatch('setAfficiencyBeneficiaries', this.beneficiaries)
        console.log(this.beneficiaries)
        console.log(this.afficiencyBeneficiaries)
        this.close()
        this.$refs.observer.reset()
      }
    }
  }
</script>

<style lang="scss" scoped>
 ::v-deep input {
    font-size: 16px!important;
  }
 ::v-deep .v-label {
    color: rgba(0, 0, 0, 0.67);
    font-size: 16px;
  }
::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 500;
  margin-bottom: 0;
  color: black;
}
</style>
