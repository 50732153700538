var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitAppQa.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.filteredQuestions),function(question){return _c('div',{key:question.questionID},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9"}},[(question.answer_category === 5)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))])]):_vm._e(),(question.questionID === 1002)?_c('div',[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(question.question_text)))]),_c('div',{staticClass:"d-inline-flex"},[_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Feet","rules":"required|numeric|min_value:4|max_value:7"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#'),expression:"'#'"}],attrs:{"outlined":"","label":"Feet","color":"primary","error-messages":errors},on:{"blur":function($event){return _vm.formatHeight(question)},"click":function($event){return _vm.clickedFieldGlobal(
                          'Underwriting feet',
                          43,
                          'Afficiency Survey v1'
                        )}},model:{value:(_vm.surveyAnswers.feet),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, "feet", $$v)},expression:"surveyAnswers.feet"}})]}}],null,true)})],1),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Inches","rules":{ required: true, regex: /\b([0-9]|1[0-1])\b/ }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"inches","color":"primary","error-messages":errors},on:{"blur":function($event){_vm.formatHeight(question), _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                          'Underwriting inches',
                          44,
                          'Afficiency Survey v1'
                        )}},model:{value:(_vm.surveyAnswers.inches),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, "inches", $$v)},expression:"surveyAnswers.inches"}})]}}],null,true)})],1)],1)]):_vm._e(),(question.questionID === 1003)?_c('div',[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(question.question_text)))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"required|numeric|min_value:0|max_value:600"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"error-messages":errors,"outlined":"","label":"Pounds"},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[question.questionID]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        'Underwriting weight',
                        45,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.nonRelfexiveBools.includes(question.questionID))?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{staticClass:"d-flex align-center",attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},_vm._l(([1, 0]),function(value){return _c('v-radio',{key:value,attrs:{"color":"blanketbutton","text":"","label":value === 1 ? 'Yes' : 'No',"outlined":"","value":value.toString()},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} ${
                        value === 1 ? 'true' : 'false'
                      }`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: value.toString()
                    });}}})}),1)],1):_vm._e(),(question.questionID === 1260)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle1260()}},model:{value:(_vm.surveyAnswers[1260]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1260, $$v)},expression:"surveyAnswers[1260]"}},_vm._l(([1, 0]),function(value){return _c('v-radio',{key:value,attrs:{"color":"blanketbutton","text":"","label":value === 1 ? 'Yes' : 'No',"outlined":"","value":value.toString()},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} ${
                        value === 1 ? 'true' : 'false'
                      }`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: value.toString()
                    });}}})}),1)],1):_vm._e(),(
                question.questionID === 1261 && _vm.surveyAnswers[1260] === '0'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle1261()}},model:{value:(_vm.surveyAnswers[1261]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1261, $$v)},expression:"surveyAnswers[1261]"}},_vm._l(([1, 0]),function(value){return _c('v-radio',{key:value,attrs:{"color":"blanketbutton","text":"","label":value === 1 ? 'Yes' : 'No',"outlined":"","value":value.toString()},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} ${
                        value === 1 ? 'true' : 'false'
                      }`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: value.toString()
                    });}}})}),1)],1):_vm._e(),(
                question.questionID === 1262 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q1262Answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    }),
                    _vm.handle1262()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[1262]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1262, $$v)},expression:"surveyAnswers[1262]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 1262 && _vm.surveyAnswers[1262] === 12623
              )?_c('div',[_c('p',[_vm._v("Please provide details of other")]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(1262, {
                      questionId: '1262',
                      answerOptionId: '1266',
                      answerValue: _vm.surveyAnswers[1266]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[1266]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1266, $$v)},expression:"surveyAnswers[1266]"}})],1)],1):_vm._e(),(
                question.questionID === 1263 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  parseInt(question.applicable_parent_answer)
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[1263]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1263, $$v)},expression:"surveyAnswers[1263]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 1264 &&
                  _vm.surveyAnswers[1263] ===
                  '1'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[1264]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1264, $$v)},expression:"surveyAnswers[1264]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 1265 &&
                  _vm.surveyAnswers[1263] ===
                  '1'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},model:{value:(_vm.surveyAnswers[1265]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1265, $$v)},expression:"surveyAnswers[1265]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(question.questionID === 1120)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle1120()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 1960 &&
                  _vm.surveyAnswers[question.Parent_question_id.toString()] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    }),
                    _vm.handle1960()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[1960]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1960, $$v)},expression:"surveyAnswers[1960]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 1961 && _vm.surveyAnswers[1960] === 19602
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1):_vm._e(),(question.questionID === 1020)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle1020()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 1021 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(
                question.questionID === 1022 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(
                question.questionID === 1990 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  parseInt(question.applicable_parent_answer)
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 2000 && _vm.surveyAnswers[1990] === '1'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":"","label":"Months"},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(question.questionID === 1040)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle1040()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 1150 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });
                    _vm.answerMap.delete(1160);
                    _vm.surveyAnswers[1160] = null;}}})],1)],1):_vm._e(),(
                question.questionID === 1130 && _vm.surveyAnswers[1020] === '0'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    }),
                    _vm.handle1130()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 1135 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  parseInt(question.applicable_parent_answer)
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    })},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}}),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return undefined}}],null,true)})]}}],null,true)})],1):_vm._e(),(question.questionID === 3700)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle3700()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(
                question.questionID === 3701 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(question.question_text)))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Gained","rules":"required|numeric|min_value:0|max_value:600"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"error-messages":errors,"outlined":"","label":"Pounds"},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[question.questionID]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1)],1):_vm._e(),(
                question.questionID === 3702 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(question.question_text)))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Lost","rules":"required|numeric|min_value:0|max_value:600"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"error-messages":errors,"outlined":"","label":"Pounds"},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[question.questionID]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1)],1):_vm._e(),(
                question.questionID === 3703 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"rules":[v => v.length > 0 || 'This field is required'],"items":_vm.q3703Answers,"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"blur":function($event){_vm.handle3703(), _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)}),(
                  _vm.surveyAnswers[3703] && _vm.surveyAnswers[3703].includes(37036)
                )?_c('div',[_c('p',[_vm._v("Please provide details of other")]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(370361, {
                        questionId: question.questionID.toString(),
                        answerOptionId: '370361',
                        answerValue: _vm.surveyAnswers[370361]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[370361]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 370361, $$v)},expression:"surveyAnswers[370361]"}})],1)],1):_vm._e()],1):_vm._e(),(question.questionID === 1080)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"rules":[v => !!v || 'This field is required'],"error-messages":errors,"items":_vm.q1080Items,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    })},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 2020 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerOptionId: _vm.surveyAnswers[
                        question.questionID
                      ].toString(),
                      answerValue: '1'
                    })},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 2025 &&
                  JSON.parse(question.applicable_parent_answer).includes(
                    _vm.surveyAnswers[question.Parent_question_id]
                  )
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":"","label":"Months"},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(question.questionID === 3710)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.showAnswers()}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: '0'
                    });
                    _vm.answerMap.delete(1150);
                    _vm.surveyAnswers[1150] = null;}}})],1)],1):_vm._e(),(question.questionID === 3720)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.q3720Answers,"rules":[v => v.length > 0 || 'This field is required'],"error-messages":errors,"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle3720()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})]}}],null,true)}),(_vm.show37201)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3720, 372011)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.q3720Items,"rules":[v => v.length > 0 || 'This field is required'],"error-messages":errors,"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle372011()},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[372011]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 372011, $$v)},expression:"surveyAnswers[372011]"}})]}}],null,true)})],1):_vm._e(),(_vm.show37202)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3720, 372021)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"rules":[v => v.length > 0 || 'This field is required'],"error-messages":errors,"items":_vm.q37202Items,"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle372021()},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[372021]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 372021, $$v)},expression:"surveyAnswers[372021]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[372011] &&
                    _vm.surveyAnswers[372011].includes(3720121)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3720, 37201211)))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(37201211, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[37201211]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[37201211]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37201211, $$v)},expression:"surveyAnswers[37201211]"}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[372021] &&
                    _vm.surveyAnswers[372021].includes(37202112)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3720, 372021121)))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(372021121, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[372021121]
                      }),
                      _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[372021121]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 372021121, $$v)},expression:"surveyAnswers[372021121]"}})],1)],1):_vm._e()],1):_vm._e(),(question.questionID === 3730)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.q3730Answers,"error-messages":errors,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle3730()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3730]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730, $$v)},expression:"surveyAnswers[3730]"}})]}}],null,true)}),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(37301)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q373011Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":_vm.handle373011,"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[373011]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373011, $$v)},expression:"surveyAnswers[373011]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730111)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37301111)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle37301111()}},model:{value:(_vm.surveyAnswers[37301111]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37301111, $$v)},expression:"surveyAnswers[37301111]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301111, {
                        questionId: '3730',
                        answerOptionId: '37301111',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301111, {
                        questionId: '3730',
                        answerOptionId: '37301111',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730116)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011116)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":function($event){return _vm.handle373011116()}},model:{value:(_vm.surveyAnswers[373011116]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373011116, $$v)},expression:"surveyAnswers[373011116]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(373011116, {
                        questionId: '3730',
                        answerOptionId: '373011116',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(373011116, {
                        questionId: '3730',
                        answerOptionId: '373011116',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[37301111] && _vm.surveyAnswers[37301111] === '1'
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011111)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q37301111Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle373011111()},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[373011111]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373011111, $$v)},expression:"surveyAnswers[373011111]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[373011116] && _vm.surveyAnswers[373011116] === '1'
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730111116)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q3730111116Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":function($event){return _vm.handle3730111116()},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[3730111116]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730111116, $$v)},expression:"surveyAnswers[3730111116]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[373011111] &&
                    _vm.surveyAnswers[373011111].includes(3730111113)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37301111131)))]),_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(37301111131, {
                      questionId: '3730',
                      answerValue: _vm.surveyAnswers[37301111131]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[37301111131]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37301111131, $$v)},expression:"surveyAnswers[37301111131]"}})],1):_vm._e(),(
                  _vm.surveyAnswers[3730111116] &&
                    _vm.surveyAnswers[3730111116].includes(37301111136)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011111316)))]),_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(373011111316, {
                      questionId: '3730',
                      answerValue: _vm.surveyAnswers[373011111316]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[373011111316]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373011111316, $$v)},expression:"surveyAnswers[373011111316]"}})],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730111)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011112)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37301112]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37301112, $$v)},expression:"surveyAnswers[37301112]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301112, {
                        questionId: '3730',
                        answerOptionId: '37301112',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301112, {
                        questionId: '3730',
                        answerOptionId: '37301112',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730116)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730111126)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3730111126]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730111126, $$v)},expression:"surveyAnswers[3730111126]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730111126, {
                        questionId: '3730',
                        answerOptionId: '3730111126',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730111126, {
                        questionId: '3730',
                        answerOptionId: '3730111126',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730111)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373011113)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37301113]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37301113, $$v)},expression:"surveyAnswers[37301113]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301113, {
                        questionId: '3730',
                        answerOptionId: '37301113',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37301113, {
                        questionId: '3730',
                        answerOptionId: '37301113',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[373011] &&
                    _vm.surveyAnswers[373011].includes(3730116)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730111136)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3730111136]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730111136, $$v)},expression:"surveyAnswers[3730111136]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730111136, {
                        questionId: '3730',
                        answerOptionId: '3730111136',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730111136, {
                        questionId: '3730',
                        answerOptionId: '3730111136',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(37302)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373021)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[373021]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373021, $$v)},expression:"surveyAnswers[373021]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(373021, {
                        questionId: '3730',
                        answerOptionId: '373021',
                        answerValue: '1'
                      });
                      _vm.surveyAnswers[3730211] = null;
                      _vm.surveyAnswers[3730212] = null;
                      _vm.answerMap.delete(3730211);
                      _vm.answerMap.delete(3730212);
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(373021, {
                        questionId: '3730',
                        answerOptionId: '373021',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(_vm.surveyAnswers[373021] && _vm.surveyAnswers[373021] === '0')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730211)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3730211]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730211, $$v)},expression:"surveyAnswers[3730211]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730211, {
                        questionId: '3730',
                        answerOptionId: '3730211',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730211, {
                        questionId: '3730',
                        answerOptionId: '3730211',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(_vm.surveyAnswers[373021] && _vm.surveyAnswers[373021] === '0')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730212)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3730212]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730212, $$v)},expression:"surveyAnswers[3730212]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730212, {
                        questionId: '3730',
                        answerOptionId: '3730212',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730212, {
                        questionId: '3730',
                        answerOptionId: '3730212',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(37303)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373031)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q373031Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":_vm.handle373031,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[373031]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373031, $$v)},expression:"surveyAnswers[373031]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[373031] &&
                    _vm.surveyAnswers[373031].includes(3730311)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37303111)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37303111]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37303111, $$v)},expression:"surveyAnswers[37303111]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37303111, {
                        questionId: '3730',
                        answerOptionId: '37303111',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37303111, {
                        questionId: '3730',
                        answerOptionId: '37303111',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[373031] &&
                    _vm.surveyAnswers[373031].includes(3730312)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37303121)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37303121]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37303121, $$v)},expression:"surveyAnswers[37303121]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37303121, {
                        questionId: '3730',
                        answerOptionId: '37303121',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37303121, {
                        questionId: '3730',
                        answerOptionId: '37303121',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(37308)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 373081)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q373081Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":_vm.handle373081,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[373081]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 373081, $$v)},expression:"surveyAnswers[373081]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[373081] &&
                    _vm.surveyAnswers[373081].includes(3730818)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37308181)))]),_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(37308181, {
                      questionId: '3730',
                      answerOptionId: '37308181',
                      answerValue: _vm.surveyAnswers[37308181]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[37308181]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37308181, $$v)},expression:"surveyAnswers[37308181]"}})],1):_vm._e(),(
                  _vm.surveyAnswers[373081] &&
                    _vm.surveyAnswers[373081].includes(3730816)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37308162)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q37308162Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":_vm.handle37308162,"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[37308162]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37308162, $$v)},expression:"surveyAnswers[37308162]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[37308162] &&
                    _vm.surveyAnswers[37308162].includes(373081623)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730816231)))]),_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(3730816231, {
                      questionId: '3730',
                      answerOptionId: '3730816231',
                      answerValue: _vm.surveyAnswers[3730816231]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3730816231]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730816231, $$v)},expression:"surveyAnswers[3730816231]"}})],1):_vm._e(),(
                  _vm.surveyAnswers[37308162] &&
                    (_vm.surveyAnswers[37308162].includes(373081621) ||
                      _vm.surveyAnswers[37308162].includes(373081622))
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730816211)))]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(3730816211, {
                      questionId: '3730',
                      answerOptionId: '3730816211',
                      answerValue: _vm.surveyAnswers[3730816211]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3730816211]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730816211, $$v)},expression:"surveyAnswers[3730816211]"}})],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(373010)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 3730101)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3730101]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3730101, $$v)},expression:"surveyAnswers[3730101]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730101, {
                        questionId: '3730',
                        answerOptionId: '3730101',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(3730101, {
                        questionId: '3730',
                        answerOptionId: '3730101',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(3730011)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37300111)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37300111]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37300111, $$v)},expression:"surveyAnswers[37300111]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37300111, {
                        questionId: '3730',
                        answerOptionId: '37300111',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37300111, {
                        questionId: '3730',
                        answerOptionId: '37300111',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e(),(
                  _vm.surveyAnswers[3730] && _vm.surveyAnswers[3730].includes(3730011)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3730, 37300112)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37300112]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37300112, $$v)},expression:"surveyAnswers[37300112]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37300112, {
                        questionId: '3730',
                        answerOptionId: '37300112',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37300112, {
                        questionId: '3730',
                        answerOptionId: '37300112',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e()],1):_vm._e(),(question.questionID === 3750)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3750]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3750, $$v)},expression:"surveyAnswers[3750]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3750, {
                      questionId: '3750',
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3750, {
                      questionId: '3750',
                      answerValue: '0'
                    });
                    _vm.surveyAnswers[3761] = null;
                    _vm.surveyAnswers[3762] = null;
                    _vm.answerMap.delete(3761);
                    _vm.answerMap.delete(3762);
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(
                question.questionID === 3761 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3761]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3761, $$v)},expression:"surveyAnswers[3761]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3761, {
                      questionId: '3761',
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3761, {
                      questionId: '3761',
                      answerValue: '0'
                    });
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(
                question.questionID === 3762 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  question.applicable_parent_answer
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-text-field',{attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(3762, {
                    questionId: '3762',
                    answerValue: _vm.surveyAnswers[3762]
                  });
                  _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                    `${question.question_text}`,
                    -1,
                    'Afficiency Survey v1'
                  )}},model:{value:(_vm.surveyAnswers[3762]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3762, $$v)},expression:"surveyAnswers[3762]"}})],1):_vm._e(),(question.questionID === 3770)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.handle3770},model:{value:(_vm.surveyAnswers[3770]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3770, $$v)},expression:"surveyAnswers[3770]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3770, {
                      questionId: '3770',
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3770, {
                      questionId: '3770',
                      answerValue: '0'
                    });
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(
                question.questionID === 3771 && _vm.surveyAnswers[3770] === '1'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q3771Items,"rules":[v => v.length > 0 || 'This field is required'],"item-text":"answer_text","item-value":"answer_option_id","multiple":"","label":"Select all that apply","outlined":""},on:{"change":_vm.handle3771,"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3771]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3771, $$v)},expression:"surveyAnswers[3771]"}})]}}],null,true)}),(
                  _vm.surveyAnswers[3771] && _vm.surveyAnswers[3771].includes(37712)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3771, 377121)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q377121Items,"item-text":"answer_text","item-value":"answer_option_id","label":"Select all that apply","outlined":""},on:{"change":function($event){_vm.answerMap.set(377121, {
                        questionId: '3771',
                        answerOptionId: _vm.surveyAnswers[377121].toString(),
                        answerValue: '1'
                      });
                      _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[377121]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 377121, $$v)},expression:"surveyAnswers[377121]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[3771] && _vm.surveyAnswers[3771].includes(37713)
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3771, 377131)))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q377131Items,"item-text":"answer_text","item-value":"answer_option_id","label":"Select all that apply","outlined":""},on:{"change":function($event){_vm.answerMap.set(377131, {
                        questionId: '3771',
                        answerOptionId: _vm.surveyAnswers[377131].toString(),
                        answerValue: '1'
                      }),
                      _vm.handle377131},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[377131]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 377131, $$v)},expression:"surveyAnswers[377131]"}})]}}],null,true)})],1):_vm._e(),(
                  _vm.surveyAnswers[377131] && _vm.surveyAnswers[377131] === 3771311
                )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.answerText(3771, 37713111)))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[37713111]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 37713111, $$v)},expression:"surveyAnswers[37713111]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} true`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37713111, {
                        questionId: '3771',
                        answerValue: '1'
                      });
                      _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                        `${question.question_text} false`,
                        -1,
                        'Afficiency Survey v1'
                      );
                      _vm.answerMap.set(37713111, {
                        questionId: '3771',
                        answerValue: '0'
                      });
                      _vm.showAnswers();}}})],1)],1):_vm._e()],1):_vm._e(),(question.questionID === 3780)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3780]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3780, $$v)},expression:"surveyAnswers[3780]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3780, {
                      questionId: '3780',
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3780, {
                      questionId: '3780',
                      answerValue: '0'
                    });
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(question.questionID === 3781)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(question.questionID === 3782)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.handle3782},model:{value:(_vm.surveyAnswers[3782]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3782, $$v)},expression:"surveyAnswers[3782]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3782, {
                      questionId: '3782',
                      answerValue: '1'
                    });
                    _vm.showAnswers();}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3782, {
                      questionId: '3782',
                      answerValue: '0'
                    });
                    _vm.showAnswers();}}})],1)],1):_vm._e(),(
                question.questionID === 3783 &&
                  _vm.surveyAnswers[3782] &&
                  _vm.surveyAnswers[3782] === '1'
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":question.answers,"item-text":"answer_text","item-value":"answer_option_id","label":"Select all that apply","outlined":""},on:{"change":function($event){_vm.answerMap.set(3783, {
                      questionId: '3783',
                      answerOptionId: _vm.surveyAnswers[3783].toString(),
                      answerValue: '1'
                    }),
                    _vm.handle377131},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3783]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3783, $$v)},expression:"surveyAnswers[3783]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 3784 &&
                  _vm.surveyAnswers[3783] &&
                  _vm.surveyAnswers[3783] === 37832
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(
                question.questionID === 3785 &&
                  _vm.surveyAnswers[3783] &&
                  _vm.surveyAnswers[3783] === 37833
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"rules":[v => !!v || 'This field is required'],"outlined":""},on:{"blur":function($event){_vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    }),
                    _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[question.questionID]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, question.questionID, $$v)},expression:"surveyAnswers[question.questionID]"}})],1)],1):_vm._e(),(question.questionID === 3805)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q3805Answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(3805, {
                      questionId: '3805',
                      answerOptionId: _vm.surveyAnswers[3805].toString(),
                      answerValue: '1'
                    })},"input":function($event){return _vm.handle3805()},"blur":_vm.showAnswers,"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3805]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3805, $$v)},expression:"surveyAnswers[3805]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 3815 &&
                  _vm.drivingViolations.includes(_vm.surveyAnswers[3805])
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3815]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3815, $$v)},expression:"surveyAnswers[3815]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3815, {
                      questionId: '3815',
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3815, {
                      questionId: '3815',
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(question.questionID === 3800)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Above","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.q3800Answers,"item-text":"answer_text","item-value":"answer_option_id","outlined":""},on:{"change":function($event){_vm.answerMap.set(3800, {
                      questionId: '3800',
                      answerOptionId: _vm.surveyAnswers[3800].toString(),
                      answerValue: '1'
                    })},"blur":function($event){_vm.handle3800(), _vm.showAnswers()},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[3800]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3800, $$v)},expression:"surveyAnswers[3800]"}})]}}],null,true)})],1):_vm._e(),(
                question.questionID === 3810 &&
                  _vm.drivingViolations.includes(_vm.surveyAnswers[3800])
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-radio-group',{attrs:{"rules":[v => !!v || 'This field is required'],"row":""},on:{"change":_vm.showAnswers},model:{value:(_vm.surveyAnswers[3810]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 3810, $$v)},expression:"surveyAnswers[3810]"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"Yes","outlined":"","value":"1"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} true`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3810, {
                      questionId: '3810',
                      answerValue: '1'
                    });}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","label":"No","value":"0"},on:{"click":function($event){_vm.clickedFieldGlobal(
                      `${question.question_text} false`,
                      -1,
                      'Afficiency Survey v1'
                    );
                    _vm.answerMap.set(3810, {
                      questionId: '3810',
                      answerValue: '0'
                    });}}})],1)],1):_vm._e(),(question.questionID === 1030)?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"prepend-icon":"mdi-currency-usd","error-messages":errors,"outlined":""},on:{"blur":function($event){_vm.showAnswers();
                      _vm.answerMap.set(question.questionID, {
                        questionId: question.questionID.toString(),
                        answerValue: _vm.surveyAnswers[question.questionID]
                      });},"click":function($event){return _vm.clickedFieldGlobal(
                        `${question.question_text}`,
                        -1,
                        'Afficiency Survey v1'
                      )}},model:{value:(_vm.surveyAnswers[1030]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1030, $$v)},expression:"surveyAnswers[1030]"}})]}}],null,true)})],1)],1):_vm._e(),(
                question.questionID === 1980 &&
                  _vm.surveyAnswers[question.Parent_question_id] ===
                  parseInt(question.applicable_parent_answer)
              )?_c('div',[_c('p',[_vm._v(_vm._s(question.question_text))]),_c('validation-provider',{attrs:{"name":"Income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"prepend-icon":"mdi-currency-usd","error-messages":errors,"outlined":""},on:{"blur":function($event){_vm.showAnswers,
                    _vm.answerMap.set(question.questionID, {
                      questionId: question.questionID.toString(),
                      answerValue: _vm.surveyAnswers[question.questionID]
                    });
                    _vm.showAnswers();},"click":function($event){return _vm.clickedFieldGlobal(
                      `${question.question_text}`,
                      -1,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.surveyAnswers[1980]),callback:function ($$v) {_vm.$set(_vm.surveyAnswers, 1980, $$v)},expression:"surveyAnswers[1980]"}})]}}],null,true)})],1):_vm._e()])],1)],1)}),_c('v-card-actions',[_c('v-btn',{staticClass:"orange white--text",attrs:{"x-large":"","to":"/"},on:{"click":function($event){return _vm.clickedFieldGlobal(
              'Underwriting Questions, Cancel',
              -1,
              'Afficiency Survey v1'
            )}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","x-large":"","disabled":_vm.loading},on:{"click":function($event){_vm.submitAppQa();
            _vm.clickedFieldGlobal(
              'Underwriting Questions, Next',
              -1,
              'Afficiency Survey v1'
            );}}},[_vm._v(" Continue ")])],1)],2)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }