<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="light-blue lighten-5 px-2 pb-16 container"
  >
    <v-dialog
      v-model="unavailable"
      theme="light"
      max-width="700"
      color="white"
      data-testid="globalErrorMessage"
      persistent
    >
      <v-card
        style="background: #fff"
        theme="light"
        min-height="33vh"
      >
        <v-card-title
          color="primary"
          class="justify-center font-weight-bold text-h5"
        >
          Sorry!
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col>
              This product is undergoing maintenance
              and is temporary unavailable.
              Please contact us at <a href="tel:1-833-333-3145">1-833-333-3145</a> for assistance with your application.
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                to="/"
              >
                Return to Home
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-------------------------------------------STEPPER--------------------------------------->
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
        class="px-2"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
          class="px-0 pb-4"
        >
          <v-stepper
            v-model="stepperStep"
            elevation="0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="stepperStep > 1"
                step="1"
              >
                Initial Info
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="stepperStep > 2"
                step="2"
              >
                Options
              </v-stepper-step>
              <v-divider class="primary" />

              <v-stepper-step
                :complete="stepperStep > 3"
                step="3"
              >
                PII
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="stepperStep > 4"
                step="4"
              >
                Underwriting
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="stepperStep >= 5"
                step="5"
              >
                Consent
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="stepperStep >= 6"
                step="6"
              >
                Beneficiary
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="stepperStep >= 7"
                step="7"
              >
                Payment
              </v-stepper-step>
            </v-stepper-header>
            <!----------------------- STEP 1: ELIGIBILITY/INITIAL INFO------------------------------------>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row
                  v-if="statusLoading || loading || policyNumLoading"
                  class="mt-12"
                >
                  <v-col class="d-flex justify-center">
                    <ellipse-progress
                      :loading="statusLoading || loading || policyNumLoading"
                      :size="180"
                      img-src="src/assets/images/termlife-icon.png"
                      thickness="5"
                      empty-thickness="3"
                      line-mode="out 5"
                      legend="false"
                      animation="rs 700 1000"
                      font-size="1.5rem"
                      color="#00A1B7"
                      empty-color-fill="transparent"
                      filename="termlife-icon.png"
                    >
                    </ellipse-progress>
                  </v-col>
                </v-row>
                <div
                  v-if="
                    !statusLoading &&
                      !loading &&
                      !policyNumLoading &&
                      completedApplication
                  "
                >
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      There is already a policy in force associated with this
                      account. You can view the details of this policy in My
                      Policies.
                    </div>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn
                          x-large
                          color="primary"
                          class="font-weight-bold"
                          to="/userpolicies"
                          @click="
                            clickedFieldGlobal(
                              'viewMyPolicies',
                              1,
                              'Afficiency Survey v1'
                            )
                          "
                        >
                          View My Policies
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <div
                  v-if="
                    !statusLoading &&
                      existingApplication &&
                      !loading &&
                      nextStep &&
                      !policyNumLoading &&
                      !completedApplication
                  "
                >
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      You already have an application in progress.
                    </div>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-spacer></v-spacer></v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn
                          x-large
                          color="primary"
                          class="font-weight-bold"
                          @click="
                            navigateApplication();
                            clickedFieldGlobal(
                              'continueApplication',
                              2,
                              'Afficiency Survey v1'
                            );
                          "
                        >
                          Continue Application
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <div
                  v-if="
                    !statusLoading &&
                      !existingApplication &&
                      !loading &&
                      !completedApplication
                  "
                >
                  <validation-observer
                    v-slot="{ invalid }"
                    ref="observer"
                  >
                    <v-card
                      min-height="300"
                      elevation="0"
                    >
                      <div
                        class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                      >
                        Some Information About You
                      </div>
                      <v-row class="justify-center">
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <p>Date of Birth</p>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="4"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Day"
                                rules="required"
                              >
                                <v-autocomplete
                                  v-model="user.bday"
                                  :items="daysavailable"
                                  label="Day"
                                  outlined
                                  color="primary"
                                  :error-messages="errors"
                                  @change="formatBirthday()"
                                  @click="
                                    clickedFieldGlobal(
                                      'dob day',
                                      3,
                                      'Afficiency Survey v1'
                                    )
                                  "
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Month"
                                rules="required"
                              >
                                <v-autocomplete
                                  v-model="user.bmonth"
                                  :items="monthsavailable"
                                  label="Month"
                                  outlined
                                  color="primary"
                                  :error-messages="errors"
                                  @change="formatBirthday()"
                                  @click="
                                    clickedFieldGlobal(
                                      'dob month',
                                      4,
                                      'Afficiency Survey v1'
                                    )
                                  "
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Year"
                                rules="required"
                              >
                                <v-autocomplete
                                  v-model="user.byear"
                                  :items="yearsavailable"
                                  label="Year"
                                  outlined
                                  color="primary"
                                  :error-messages="errors"
                                  @change="formatBirthday()"
                                  @click="
                                    clickedFieldGlobal(
                                      'dob year',
                                      5,
                                      'Afficiency Survey v1'
                                    )
                                  "
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="invalidBirthday"
                              cols="12"
                            >
                              <p class="red--text font-weight-bold text-center">
                                Please enter a valid date.
                              </p>
                            </v-col>
                          </v-row>
                          <p>Sex:</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Sex"
                            rules="required"
                          >
                            <v-radio-group
                              v-model="user.gender"
                              name="Gender"
                              row
                              :error-messages="errors"
                            >
                              <v-radio
                                color="blanketbutton"
                                text
                                value="M"
                                label="Male"
                                class="font-weight-bold"
                                @click="
                                  clickedFieldGlobal(
                                    'gender male',
                                    6,
                                    'Afficiency Survey v1'
                                  )
                                "
                              />
                              <v-radio
                                color="blanketbutton"
                                text
                                value="F"
                                label="Female"
                                class="font-weight-bold"
                                @click="
                                  clickedFieldGlobal(
                                    'gender female',
                                    7,
                                    'Afficiency Survey v1'
                                  )
                                "
                              />
                            </v-radio-group>
                          </validation-provider>
                          <p>What is your Zip Code?</p>
                          <v-row>
                            <v-col>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Zip Code"
                                rules="required|numeric"
                              >
                                <v-autocomplete
                                  v-model="user.zipCode"
                                  :items="allzips"
                                  :error-messages="errors"
                                  :autocomplete="Date.now()"
                                  counter="5"
                                  label="Zip code"
                                  color="primary"
                                  outlined
                                  @change="getpostcodedata"
                                  @click="
                                    clickedFieldGlobal(
                                      'zip',
                                      8,
                                      'Afficiency Survey v1'
                                    )
                                  "
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                              >
                                <v-text-field
                                  v-model="user.email"
                                  label="Email"
                                  outlined
                                  append-icon="mdi-email-outline"
                                  :error-messages="errors"
                                  @click="
                                    clickedFieldGlobal(
                                      'email',
                                      9,
                                      'Afficiency Survey v1'
                                    )
                                  "
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <p>
                            Do you currently use any tobacco products?
                          </p>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="pa-0 ma-0"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Tobacco Products"
                              rules="required"
                            >
                              <v-select
                                v-model="user.tobaccoUse"
                                outlined
                                color="primary"
                                label="Select One"
                                :items="lists.tobacco"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'tobacco',
                                    10,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="pa-0 ma-0"
                          >
                            <p>
                              Enter your height.
                            </p>
                            <div class="d-inline-flex">
                              <v-col
                                cols="6"
                                class="ml-0 pl-0"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Feet"
                                  rules="required|numeric|min_value:4|max_value:7"
                                >
                                  <v-text-field
                                    v-model="feet"
                                    v-mask="'#'"
                                    outlined
                                    label="Feet"
                                    color="primary"
                                    :error-messages="errors"
                                    @click="
                                      clickedFieldGlobal(
                                        'height in feet',
                                        11,
                                        'Afficiency Survey v1'
                                      )
                                    "
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="6"
                                class="ml-0 pl-0"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Inches"
                                  :rules="{
                                    required: true,
                                    regex: /\b([0-9]|1[0-1])\b/
                                  }"
                                >
                                  <v-text-field
                                    v-model="inches"
                                    v-mask="'##'"
                                    outlined
                                    label="inches"
                                    color="primary"
                                    :error-messages="errors"
                                    @click="
                                      clickedFieldGlobal(
                                        'height in inches',
                                        12,
                                        'Afficiency Survey v1'
                                      )
                                    "
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            class="pa-0 ma-0"
                          >
                            <p>
                              Enter your weight in pounds.
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Weight"
                              rules="required|numeric|min_value:0|max_value:600"
                            >
                              <v-text-field
                                v-model.trim.number="user.weight"
                                v-mask="'###'"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'weight',
                                    13,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            class="pa-0 ma-0"
                          >
                            <p>
                              Choose your term length.
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Term"
                              rules="required"
                            >
                              <v-select
                                v-model="user.term"
                                :items="[10, 15, 20]"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'term length',
                                    14,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            class="pa-0 ma-0"
                          >
                            <p>
                              Choose your coverage amount.
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Coverage"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model.number="user.coverage"
                                :items="faceAmounts"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'coverage amount',
                                    15,
                                    'Afficiency Survey v1'
                                  )
                                "
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row
                      v-if="loading"
                      class="mt-12"
                    >
                      <v-col class="d-flex justify-center">
                        <ellipse-progress
                          :loading="loading"
                          :size="180"
                          img-src="src/assets/images/termlife-icon.png"
                          thickness="5"
                          empty-thickness="3"
                          line-mode="out 5"
                          legend="false"
                          animation="rs 700 1000"
                          font-size="1.5rem"
                          color="#00A1B7"
                          empty-color-fill="transparent"
                          filename="termlife-icon.png"
                        >
                        </ellipse-progress>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn
                          x-large
                          color="primary"
                          class="font-weight-bold"
                          :disabled="loading || invalid || invalidBirthday"
                          @click="
                            scroll();
                            getQuote();
                            clickedFieldGlobal(
                              'getYourQuote',
                              16,
                              'Afficiency Survey v1'
                            );
                          "
                        >
                          Get Your Quote
                        </v-btn>
                      </v-col>
                    </v-row>
                  </validation-observer>
                </div>
              </v-stepper-content>
              <!---------------------------------POLICY OPTIONS---------------------------------------->
              <v-stepper-content step="2">
                <validation-observer ref="observer">
                  <div
                    v-if="
                      !statusLoading &&
                        !loading &&
                        !policyNumLoading &&
                        completedApplication
                    "
                  >
                    <v-card
                      min-height="300"
                      elevation="0"
                    >
                      <div
                        class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                      >
                        There is already a policy in force associated with this
                        account. You can view the details of this policy in My
                        Policies.
                      </div>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            x-large
                            color="primary"
                            class="font-weight-bold"
                            to="/userpolicies"
                            @click="
                              clickedFieldGlobal(
                                'Option viewMyPolicies',
                                17,
                                'Afficiency Survey v1'
                              )
                            "
                          >
                            View My Policies
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                  <div
                    v-if="
                      !statusLoading &&
                        existingApplication &&
                        !loading &&
                        nextStep &&
                        !policyNumLoading
                    "
                  >
                    <v-card
                      min-height="300"
                      elevation="0"
                    >
                      <div
                        class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                      >
                        You already have an application in progress.
                      </div>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col><v-spacer></v-spacer></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            x-large
                            color="primary"
                            class="font-weight-bold"
                            @click="
                              navigateApplication();
                              clickedFieldGlobal(
                                'Option continueApplication',
                                18,
                                'Afficiency Survey v1'
                              );
                            "
                          >
                            Continue Application
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div v-if="!userpresent && !loading">
                      <v-row class="justify-center">
                        <v-col
                          cols="12"
                          md="10"
                        >
                          <v-card>
                            <v-card-text>
                              <v-row class="d-flex justify-center">
                                <v-col
                                  class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                                >
                                  In order to continue with the application or
                                  save your quote, please log in or create a
                                  Blanket account.
                                </v-col>
                              </v-row>
                              <v-row>
                                {{ errorMessage }}
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-form ref="form">
                                    <validation-observer
                                      ref="observer"
                                      v-slot="{ invalid }"
                                    >
                                      <v-row class="d-flex justify-center">
                                        <v-col class="pb-0">
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                          >
                                            <v-text-field
                                              v-model="user.email"
                                              label="Email"
                                              outlined
                                              append-icon="mdi-email-outline"
                                              :error-messages="errors"
                                              @click="
                                                clickedFieldGlobal(
                                                  'Option userEmail',
                                                  19,
                                                  'Afficiency Survey v1'
                                                )
                                              "
                                            ></v-text-field>
                                          </validation-provider>
                                        </v-col>
                                      </v-row>
                                      <v-row class="d-flex justify-center">
                                        <v-col class="pb-0">
                                          <validation-provider
                                            v-slot="{ errors }"
                                            vid="password"
                                            name="Password"
                                            rules="required"
                                          >
                                            <v-text-field
                                              ref="password"
                                              v-model="user.password"
                                              name="password"
                                              label="Password"
                                              outlined
                                              :type="show ? 'text' : 'password'"
                                              :append-icon="
                                                show
                                                  ? 'mdi-eye-outline'
                                                  : 'mdi-eye-off'
                                              "
                                              :error-messages="errors"
                                              @click:append="
                                                show = !show;
                                                clickedFieldGlobal(
                                                  'Option userPassword',
                                                  20,
                                                  'Afficiency Survey v1'
                                                );
                                              "
                                            >
                                            </v-text-field>
                                          </validation-provider>
                                        </v-col>
                                      </v-row>
                                      <v-row class="d-flex justify-center">
                                        <v-col
                                          cols="6"
                                          class="d-flex justify-center"
                                        >
                                          <v-btn
                                            class="primary whtie--text mr-0"
                                            block
                                            large
                                            :disabled="invalid"
                                            @keyup.enter="submit"
                                            @click="
                                              loginSubmit();
                                              clickedFieldGlobal(
                                                'Option logInButton',
                                                21,
                                                'Afficiency Survey v1'
                                              );
                                            "
                                          >
                                            <span
                                              class="login-btn"
                                            >Log In</span>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </validation-observer>
                                  </v-form>
                                  <v-row class="d-flex justify-center">
                                    <v-col>
                                      <div class="signup-text text-center">
                                        <span
                                          style="color: #757575;"
                                        >Don't have an account?
                                        </span>
                                        <!-- <button
                                          text
                                          class="primary--text font-weight-bold"
                                          @click="
                                            createAccount();
                                            clickedFieldGlobal(
                                              'Option createAccForMe',
                                              22,
                                              'Afficiency Survey v1'
                                            );
                                          "
                                        >
                                          Create One For Me using:
                                          {{ user.email }}
                                        </button> -->
                                        <create-account-button
                                          :button-text="'Create One For Me using: ' + user.email"
                                          :button-classes="['primary--text','font-weight-bold','theme--light','v-btn--text','v-btn']"
                                          :large="false"
                                          :block="false"
                                          :email="this.user.email"
                                          :password="this.user.password"
                                          :post-signup-callback="postSignup"
                                          :text="true"
                                        />
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="!statusLoading && !loading && !policyNumLoading"
                      class="estimate-title primary--text my-12"
                    >
                      Premium Estimate
                    </div>
                    <div
                      v-if="!statusLoading && !loading && !policyNumLoading"
                      class="px-0"
                    >
                      <div v-if="monthlyEstimate || savedQuote.premium">
                        <p class="estimate-subtitle">
                          <span>Your estimate: </span>
                          <span class="font-weight-bold primary--text">{{
                            quote
                              ? "$" + monthlyEstimate.toFixed(2) + "/month"
                              : "$" + savedQuote.premium.toFixed(2) + "/month"
                          }}</span>
                        </p>
                      </div>
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          sm="10"
                          md="10"
                        >
                          <v-card
                            v-if="!error"
                            elevation="0"
                            class="text-center plan-card pa-6 mx-0"
                          >
                            <div
                              class="d-inline-flex align-center justify-center mt-2 mb-2"
                            >
                              <div class="switch-text ml-2">
                                Policy Options
                              </div>
                            </div>
                            <v-row class="d-flex align-center mt-2">
                              <v-col
                                cols="4"
                                class="px-0"
                              >
                                <span
                                  :class="
                                    mobileDevice
                                      ? 'offer-text-mobile'
                                      : 'offer-text'
                                  "
                                >Premium</span>
                                <br />
                                <v-chip
                                  v-if="monthlyEstimate || savedQuote.premium"
                                  class="primary--text mt-4"
                                  outlined
                                  color="primary"
                                >
                                  <span
                                    :class="
                                      mobileDevice
                                        ? 'chip-text-mobile'
                                        : 'chip-text'
                                    "
                                  >${{
                                    quote
                                      ? monthlyEstimate.toFixed(2)
                                      : savedQuote.premium.toFixed(2)
                                  }}</span>
                                </v-chip>
                              </v-col>
                              <v-col
                                cols="4"
                                class="px-0"
                              >
                                <div
                                  :class="
                                    mobileDevice
                                      ? 'offer-text-mobile'
                                      : 'offer-text'
                                  "
                                >
                                  Coverage Amount
                                </div>
                                <v-chip
                                  v-if="quote || savedQuote"
                                  class="gray--text mt-4"
                                  outlined
                                  color="primary"
                                >
                                  <span
                                    :class="
                                      mobileDevice
                                        ? 'chip-text-mobile'
                                        : 'chip-text'
                                    "
                                  >${{
                                    quote
                                      ? quote.coverage
                                      : savedQuote.coverage
                                  }}</span>
                                </v-chip>
                              </v-col>
                              <v-col
                                cols="4"
                                class="px-0"
                              >
                                <div
                                  :class="
                                    mobileDevice
                                      ? 'offer-text-mobile'
                                      : 'offer-text'
                                  "
                                >
                                  Term Length
                                </div>
                                <v-chip
                                  v-if="quote || savedQuote"
                                  class="gray--text mt-4"
                                  outlined
                                  color="primary"
                                >
                                  <span
                                    :class="
                                      mobileDevice
                                        ? 'chip-text-mobile'
                                        : 'chip-text'
                                    "
                                  >{{
                                    quote ? quote.term : savedQuote.term
                                  }}
                                    Years</span>
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                      <div
                        class="text-center text-h3 font-weight-bold primary--text my-12"
                      >
                        Adjust your quote
                      </div>
                      <v-row class="d-felx justify-center">
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Term"
                            rules="required"
                          >
                            <v-select
                              v-model="user.term"
                              :items="[10, 15, 20]"
                              outlined
                              label="Term Length"
                              color="primary"
                              :error-messages="errors"
                              @click="
                                clickedFieldGlobal(
                                  'Option term length',
                                  23,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Coverage"
                            rules="required"
                          >
                            <v-autocomplete
                              v-model.number="user.coverage"
                              :items="faceAmounts"
                              outlined
                              label="Coverage Amount"
                              color="primary"
                              :error-messages="errors"
                              @click="
                                clickedFieldGlobal(
                                  'Option face amount',
                                  24,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-autocomplete>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center pa-2 ma-2">
                        <v-col
                          class="d-flex justify-center"
                          cols="12"
                          sm="4"
                          md="3"
                          xl="2"
                        >
                          <v-btn
                            x-large
                            block
                            color="primary"
                            class="font-weight-bold mr-0"
                            :disabled="loading || !userId"
                            @click="
                              saveQuote();
                              clickedFieldGlobal(
                                'Option saveQuoteBtn',
                                25,
                                'Afficiency Survey v1'
                              );
                            "
                          >
                            Save Quote
                          </v-btn>
                        </v-col>
                        <v-col
                          class="d-flex justify-center"
                          cols="12"
                          sm="4"
                          md="3"
                          xl="2"
                        >
                          <v-btn
                            x-large
                            block
                            color="secondary"
                            class="font-weight-bold mr-0"
                            :disabled="loading || !user.term || !user.coverage"
                            @click="
                              recalculateQuote();
                              clickedFieldGlobal(
                                'Option recalculateBtn',
                                26,
                                'Afficiency Survey v1'
                              );
                            "
                          >
                            Recalculate Quote
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row
                      v-if="loading"
                      class="mt-12"
                    >
                      <v-col class="d-flex justify-center">
                        <ellipse-progress
                          :loading="loading"
                          :size="180"
                          img-src="src/assets/images/termlife-icon.png"
                          thickness="5"
                          empty-thickness="3"
                          line-mode="out 5"
                          legend="false"
                          animation="rs 700 1000"
                          font-size="1.5rem"
                          color="#00A1B7"
                          empty-color-fill="transparent"
                          filename="termlife-icon.png"
                        >
                        </ellipse-progress>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row
                    v-if="
                      !statusLoading &&
                        !existingApplication &&
                        !loading &&
                        !policyNumLoading
                    "
                    class="d-flex justify-center pa-2 ma-2"
                  >
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      class="d-flex justify-space-between"
                    >
                      <v-col
                        class="pl-0"
                        cols="6"
                        sm="3"
                        md="2"
                        xl="1"
                      >
                        <v-btn
                          x-large
                          block
                          class="orange white--text font-weight-bold ml-0"
                          @click="
                            cancel();
                            clickedFieldGlobal(
                              'Option goBackBtn',
                              27,
                              'Afficiency Survey v1'
                            );
                          "
                        >
                          Back
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="3"
                        md="2"
                        xl="1"
                      >
                        <v-btn
                          x-large
                          block
                          color="success"
                          class="font-weight-bold mr-0"
                          :disabled="!userpresent || loading"
                          @click="
                            stepperStep++;
                            scroll();
                            clickedFieldGlobal(
                              'Option applyNowBtn',
                              28,
                              'Afficiency Survey v1'
                            );
                          "
                        >
                          {{ continueLabel }}
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </validation-observer>
              </v-stepper-content>
              <!-------------------------------------PII-------------------------------------------->
              <v-stepper-content step="3">
                <personally-identifiable-information
                  :user="user"
                  :saved-quote="savedQuote"
                  :cancel="cancel"
                  :next-question="nextquestion"
                  :init-app="initApp"
                  :getaddress="getResidentialAddressData"
                  :step="stepperStep"
                  @next="navigateApplication"
                ></personally-identifiable-information>
              </v-stepper-content>
              <!-------------------------------------UNDERWRITING SURVEY-------------------------------------->
              <v-stepper-content step="4">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <v-row
                    v-if="loading"
                    class="mt-12"
                  >
                    <v-col class="d-flex justify-center">
                      <ellipse-progress
                        :loading="loading"
                        :size="180"
                        img-src="src/assets/images/termlife-icon.png"
                        thickness="5"
                        empty-thickness="3"
                        line-mode="out 5"
                        legend="false"
                        animation="rs 700 1000"
                        font-size="1.5rem"
                        color="#00A1B7"
                        empty-color-fill="transparent"
                        filename="termlife-icon.png"
                      >
                      </ellipse-progress>
                    </v-col>
                  </v-row>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <div class="estimate-title primary--text mb-12">
                      Underwriting Questions
                    </div>
                    <survey
                      :user="user"
                      :survey-answers="surveyAnswers"
                      :questions="surveyQuestions"
                      :next-question="nextquestion"
                      :answer-map="answerMap"
                      @updateanswers="updateAnswers"
                    ></survey>
                    <v-card-actions> </v-card-actions>
                  </div>
                </v-card>
              </v-stepper-content>
              <!-------------------------------------FORMS-------------------------------------->
              <v-stepper-content step="5">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <v-row
                    v-if="loading"
                    class="mt-12"
                  >
                    <v-col class="d-flex justify-center">
                      <ellipse-progress
                        :loading="loading"
                        :size="180"
                        img-src="src/assets/images/termlife-icon.png"
                        thickness="5"
                        empty-thickness="3"
                        line-mode="out 5"
                        legend="false"
                        animation="rs 700 1000"
                        font-size="1.5rem"
                        color="#00A1B7"
                        empty-color-fill="transparent"
                        filename="termlife-icon.png"
                      >
                      </ellipse-progress>
                    </v-col>
                  </v-row>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="10"
                      >
                        <v-card class="pa-4">
                          <v-row>
                            <v-col
                              class="text-h3 ma-2 font-weight-bold primary--text text-center"
                            >
                              By clicking below, I agree with these Terms and
                              Conditions
                            </v-col>
                          </v-row>
                          <v-progress-linear
                            :active="pdfsloading"
                            :indeterminate="pdfsloading"
                            absolute
                            color="primary"
                          />
                          <v-card-text
                            v-if="!pdfsloading"
                            class="headline-1 ma-4 pa-4"
                          >
                            <p>
                              By reviewing and agreeing to the following
                              authorizations and disclosures, you allow the
                              insurer (SBLI) to contact contact any physicians,
                              hospitals, pharmacies, pharmacy benefit managers,
                              consumer reporting agencies or any other entity
                              that possesses prescription, medical, financial or
                              other information about you for the purpose of
                              evaluating your eligibility for insurance.
                            </p>
                            <p>
                              Your authorization overrides any restrictions that
                              you may have in place with any entity regarding
                              the release of your information. Any information
                              gathered will not be disclosed without your
                              approval unless required or permitted by law, in
                              which case it may not be protected under privacy
                              rules. Your authorization remains valid from this
                              date forward and may be revoked by sending written
                              notice to the insurer (SBLI).
                            </p>
                          </v-card-text>
                          <v-col v-if="!pdfsloading">
                            <v-row
                              v-for="item in noticeListFiles"
                              :key="item.name"
                              class="justify-center"
                            >
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="9"
                                >
                                  <v-card-text
                                    class="headline-1 ma-4 pa-4 blanketcolor--text"
                                  >
                                    <a
                                      :href="item.url"
                                      target="_blank"
                                      @click="
                                        clickedFieldGlobal(
                                          'Option applyNowBtn',
                                          46,
                                          'Afficiency Survey v1'
                                        )
                                      "
                                    >
                                      {{ item.name }}</a>
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-col>

                          <v-card-actions>
                            <v-btn
                              class="orange white--text"
                              :disabled="nextStep === 'BENE'"
                              @click="
                                backToSurvey();
                                clickedFieldGlobal(
                                  'Consent previousBtn',
                                  47,
                                  'Afficiency Survey v1'
                                );
                              "
                            >
                              Previous
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="
                                submitAuth();
                                clickedFieldGlobal(
                                  'Consent agreeBtn',
                                  48,
                                  'Afficiency Survey v1'
                                );
                              "
                            >
                              I Agree
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="6">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <v-row
                    v-if="loading"
                    class="mt-12"
                  >
                    <v-col class="d-flex justify-center">
                      <ellipse-progress
                        :loading="loading"
                        :size="180"
                        img-src="src/assets/images/termlife-icon.png"
                        thickness="5"
                        empty-thickness="3"
                        line-mode="out 5"
                        legend="false"
                        animation="rs 700 1000"
                        font-size="1.5rem"
                        color="#00A1B7"
                        empty-color-fill="transparent"
                        filename="termlife-icon.png"
                      >
                      </ellipse-progress>
                    </v-col>
                  </v-row>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <div class="estimate-title primary--text mb-12">
                      Beneficiary Information (Min 1, Max 10)
                    </div>
                    <v-card elevation="0">
                      <afficiency-beneficiary
                        :user="user"
                        :beneficiaries="beneficiaries"
                        @cancel="cancel"
                        @next="nextquestion('afficiencyBeneficiaries')"
                      ></afficiency-beneficiary>
                    </v-card>
                  </div>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="7">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    v-if="
                      !notApprovedMsg &&
                        (decisionLoading ||
                          !sessionId ||
                          !premium ||
                          !policyNumber ||
                          loading)
                    "
                    class="mt-12"
                  >
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <ellipse-progress
                          :loading="
                            !notApprovedMsg &&
                              (decisionLoading ||
                                !sessionId ||
                                !premium ||
                                !policyNumber ||
                                loading)
                          "
                          :size="180"
                          img-src="src/assets/images/termlife-icon.png"
                          thickness="5"
                          empty-thickness="3"
                          line-mode="out 5"
                          legend="false"
                          animation="rs 700 1000"
                          font-size="1.5rem"
                          color="#00A1B7"
                          empty-color-fill="transparent"
                          filename="termlife-icon.png"
                        >
                        </ellipse-progress>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !notApprovedMsg &&
                          (decisionLoading || !sessionId || !policyNumber)
                      "
                    >
                      <v-col class="d-flex justify-center">
                        <p class="text-center font-weight-bold">
                          We are processing your life insurance to get your best
                          price. This may take several minutes. Please do not
                          refresh or close this window.
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="
                      !decisionLoading &&
                        sessionId &&
                        premium &&
                        policyNumber &&
                        !loading
                    "
                    class="px-0"
                  >
                    <div class="estimate-title primary--text mb-12">
                      Congratulations, you're Approved!
                    </div>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-card
                          elevation="2"
                          class="pa-4"
                        >
                          <div
                            class="text-h3 primary--text font-weight-bold text-center"
                          >
                            Offer Summary
                          </div>
                          <v-card-text>
                            <span class="font-weight-bold">Term:</span>
                            {{ user.term ? user.term : appData.term }} years
                            <br />
                            <span class="font-weight-bold">Coverage:</span> ${{
                              premium.approvedCoverage
                            }}
                            <br />
                            <span class="font-weight-bold">Carrier:</span>
                            {{
                              user.state === "CT"
                                ? "Centrian"
                                : "Savings Bank Life Insurance Company of Massachusetts (SBLI)"
                            }}
                            <br />
                            <v-list flat>
                              <v-list-item
                                v-for="(item, i) in offerList"
                                :key="i"
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    class="black--text"
                                    v-text="item.icon"
                                  ></v-icon>
                                </v-list-item-icon>

                                <v-list-item class="pb-2">
                                  {{ item.text }}
                                </v-list-item>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                          <v-radio-group
                            v-if="premium"
                            v-model="paymentFrequency"
                            column
                            @change="logAmount()"
                          >
                            <v-radio
                              :label="
                                'Monthly Premium' +
                                  ' ($' +
                                  premium.premiumMonthly.toFixed(2) +
                                  ')'
                              "
                              value="Monthly"
                              @click="
                                clickedFieldGlobal(
                                  'Payment premiumMonthlyPaymentFreq',
                                  76,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-radio>
                            <v-radio
                              :label="
                                'Quarterly Premium' +
                                  ' ($' +
                                  premium.premiumQuaterly.toFixed(2) +
                                  ')'
                              "
                              value="Quarterly"
                              @click="
                                clickedFieldGlobal(
                                  'Payment premiumQuarterlyPaymentFreq',
                                  77,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-radio>
                            <v-radio
                              :label="
                                'Semi-Annual Premium' +
                                  ' ($' +
                                  premium.premiumSemiAnnually.toFixed(2) +
                                  ')'
                              "
                              value="SemiAnnually"
                              @click="
                                clickedFieldGlobal(
                                  'Payment premiumSemiAnnuallyPaymentFreq',
                                  78,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-radio>
                            <v-radio
                              :label="
                                'Annual Premium' +
                                  ' ($' +
                                  premium.premiumAnnually.toFixed(2) +
                                  ')'
                              "
                              value="Annually"
                              @click="
                                clickedFieldGlobal(
                                  'Payment premiumAnuallyPaymentFreq',
                                  79,
                                  'Afficiency Survey v1'
                                )
                              "
                            ></v-radio>
                          </v-radio-group>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row
                    v-if="sessionId && premium && policyNumber"
                    class="d-flex justify-center"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-center"
                    >
                      <portal-one
                        :amount="paymentAmount"
                        :frequency="paymentFrequency"
                        :error="error"
                        :next-step="nextStep"
                        :coverage="paymentCoverage"
                        :term="paymentTerm"
                        @complete="navigateApplication"
                      ></portal-one>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="sessionId && premium && policyNumber"
                    class="disply-flex justify-space-around"
                  >
                    <v-col cols="auto">
                      <v-dialog
                        v-model="achDialog"
                        transition="dialog-bottom-transition"
                        max-width="600"
                        persistent
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="primary font-weight-bold"
                            x-large
                            v-bind="attrs"
                            :disabled="
                              disableMakePayment ||
                                nextStep === 'SUBMIT' ||
                                loading
                            "
                            v-on="on"
                            @click="
                              achDialog = true;
                              clickedFieldGlobal(
                                'Payment makeBankPayment',
                                80,
                                'Afficiency Survey v1'
                              );
                            "
                          >
                            Accept & Make Bank Payment
                          </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <validation-observer
                            v-slot="{ invalid }"
                            ref="observer"
                          >
                            <v-card>
                              <v-toolbar
                                class="text-center font-weight-bold"
                                color="primary"
                                dark
                              >
                                ACH Bank Payment
                              </v-toolbar>
                              <v-card-text>
                                <v-form>
                                  <v-col cols="auto">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Bank Name"
                                      rules="required"
                                    >
                                      <v-text-field
                                        v-model="bankName"
                                        :error-messages="errors"
                                        outlined
                                        label="Bank Name"
                                        @click="
                                          clickedFieldGlobal(
                                            'Payment bankName',
                                            81,
                                            'Afficiency Survey v1'
                                          )
                                        "
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="auto">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Routing Number"
                                      rules="required|numeric"
                                    >
                                      <v-text-field
                                        v-model="bankRoutingNo"
                                        :error-messages="errors"
                                        outlined
                                        label="Routing Number"
                                        @click="
                                          clickedFieldGlobal(
                                            'Payment bankRoutingNumber',
                                            82,
                                            'Afficiency Survey v1'
                                          )
                                        "
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="auto">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Account Number"
                                      rules="required|numeric"
                                    >
                                      <v-text-field
                                        v-model="bankAccountNo"
                                        :error-messages="errors"
                                        outlined
                                        label="Account Number"
                                        @click="
                                          clickedFieldGlobal(
                                            'Payment bankAccNumber',
                                            83,
                                            'Afficiency Survey v1'
                                          )
                                        "
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="auto">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Account Number"
                                      rules="required"
                                    >
                                      <v-select
                                        v-model="bankAccountType"
                                        :items="accountTypes"
                                        :error-messages="errors"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        label="Account Type"
                                        @click="
                                          clickedFieldGlobal(
                                            'Payment bankAccType',
                                            84,
                                            'Afficiency Survey v1'
                                          )
                                        "
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                </v-form>
                              </v-card-text>
                              <v-card-actions class="justify-center">
                                <v-btn
                                  class="orange white--text font-weight-bold"
                                  :disabled="loading"
                                  @click="
                                    dialog.value = false;
                                    clickedFieldGlobal(
                                      'Payment closeACHPayment',
                                      85,
                                      'Afficiency Survey v1'
                                    );
                                  "
                                >
                                  Close
                                </v-btn>
                                <v-btn
                                  :disabled="invalid || loading"
                                  class="success white--text font-weight-bold mr-0"
                                  @click="
                                    makeACHPayment();
                                    clickedFieldGlobal(
                                      'Payment makeACHPayment',
                                      86,
                                      'Afficiency Survey v1'
                                    );
                                  "
                                >
                                  Submit
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </validation-observer>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn
                      x-large
                      class="orange white--text"
                      :disabled="paymentPageReady || disableBack || loading"
                      @click="
                        cancel();
                        clickedFieldGlobal(
                          'Payment gobackBeneficiaryBtn',
                          87,
                          'Afficiency Survey v1'
                        );
                      "
                    >
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      color="primary"
                      x-large
                      :disabled="nextStep !== 'SUBMIT'"
                      @click="navigateApplication"
                    >
                      Continue
                    </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="8">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <v-row
                    v-if="loading"
                    class="mt-12"
                  >
                    <v-col class="d-flex justify-center">
                      <ellipse-progress
                        :loading="loading"
                        :size="180"
                        img-src="src/assets/images/termlife-icon.png"
                        thickness="5"
                        empty-thickness="3"
                        line-mode="out 5"
                        legend="false"
                        animation="rs 700 1000"
                        font-size="1.5rem"
                        color="#00A1B7"
                        empty-color-fill="transparent"
                        filename="termlife-icon.png"
                      >
                      </ellipse-progress>
                    </v-col>
                  </v-row>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <v-row>
                      <v-col class="estimate-title primary--text mb-12">
                        Review and Sign
                      </v-col>
                    </v-row>
                    <v-card
                      class="scrollable"
                      elevation="0"
                    >
                      <v-row>
                        <v-col>
                          <div
                            v-for="item in finalPdfs"
                            :key="item.name"
                            width="100%"
                          >
                            <vue-pdf-embed
                              :source="item.url"
                              @loaded="pdfLoaded = true"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card-text>
                      <v-row class="ma-4 pa-4">
                        <v-col
                          cols="12"
                          sm="6"
                          md="9"
                        >
                          <div class="d-inline-flex align-center">
                            <v-checkbox v-model="appSignBox"></v-checkbox>
                            <p class="mb-0 pb-0 font-weight-bold">
                              By checking this box and clicking 'Submit' below,
                              I agree to electronically sign the documents shown
                              here as of today's date.
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary font-weight-bold"
                        x-large
                        :disabled="!appSignBox || loading"
                        @click="
                          signApplication();
                          clickedFieldGlobal(
                            'submitSignedApplication',
                            88,
                            'Afficiency Survey v1'
                          );
                        "
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="9">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <v-row
                    v-if="loading"
                    class="mt-12"
                  >
                    <v-col class="d-flex justify-center">
                      <ellipse-progress
                        :loading="loading"
                        :size="180"
                        img-src="src/assets/images/termlife-icon.png"
                        thickness="5"
                        empty-thickness="3"
                        line-mode="out 5"
                        legend="false"
                        animation="rs 700 1000"
                        font-size="1.5rem"
                        color="#00A1B7"
                        empty-color-fill="transparent"
                        filename="termlife-icon.png"
                      >
                      </ellipse-progress>
                    </v-col>
                  </v-row>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <v-row>
                      <v-col
                        class="d-inline-flex align-center mt-16 justify-center"
                      >
                        <v-img
                          src="/img/celebration 1.svg"
                          :height="mobileDevice ? '40px' : '64px'"
                          :max-width="mobileDevice ? '40px' : '64px'"
                          class="mr-4"
                        ></v-img>
                        <div
                          :class="
                            mobileDevice ? 'title-text-mobile' : 'title-text'
                          "
                        >
                          Congratulations!
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p class="title-subtext">
                          Thank you for your purchase. You'll get an email with
                          instructions on how to register and download your
                          policy package.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="11"
                        md="11"
                        lg="10"
                      >
                        <v-card elevation="0">
                          <div v-if="!mobileDevice && appData">
                            <v-row
                              class="grey-background grey-outline table-fields"
                            >
                              <v-col cols="2">
                                Provider
                              </v-col>
                              <v-col cols="3">
                                Coverage
                              </v-col>
                              <v-col cols="2">
                                Term
                              </v-col>
                              <v-col cols="2">
                                Premium
                              </v-col>
                              <v-col cols="3">
                                Policy No.
                              </v-col>
                            </v-row>
                            <v-row class="grey-outline table-fields">
                              <v-col cols="2">
                                {{ user.state === "CT" ? "Centrian" : "SBLI" }}
                              </v-col>
                              <v-col cols="3">
                                {{ appData.coverage }}
                              </v-col>
                              <v-col cols="2">
                                {{ appData.term }}
                              </v-col>
                              <v-col cols="2">
                                <v-chip class="primary">
                                  <span
                                    v-if="appData.paymentPremium"
                                    class="table-fields white--text"
                                  >$
                                    {{
                                      appData.paymentPremium.toFixed(2)
                                    }}</span>
                                </v-chip>
                              </v-col>
                              <v-col cols="3">
                                {{ appData.policyNum }}
                              </v-col>
                            </v-row>
                          </div>
                          <div v-if="mobileDevice && appData">
                            <v-row class="table-fields d-flex justify-center">
                              <v-col
                                cols="11"
                                class="grey-background grey-outline"
                              >
                                <v-col cols="12">
                                  Provider:
                                  {{
                                    user.state === "CT" ? "Centrian" : "SBLI"
                                  }}
                                </v-col>
                                <v-col cols="12">
                                  Coverage : {{ appData.coverage }}
                                </v-col>
                                <v-col cols="12">
                                  Term : {{ appData.term }}
                                </v-col>
                                <v-col cols="12">
                                  Premium Cost :
                                  <v-chip class="primary">
                                    <span
                                      v-if="appData.paymentPremium"
                                      class="table-fields white--text"
                                    >${{
                                      appData.paymentPremium.toFixed(2)
                                    }}</span>
                                  </v-chip>
                                </v-col>
                                <v-col cols="12">
                                  Policy No. : {{ appData.policyNum }}
                                </v-col>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="11"
                        class="d-flex justify-center"
                      >
                        <div class="policy-txt">
                          This policy has been added to Your Blanket Account.
                          You can view all of your policies under
                          <a
                            class="policy-txt primary--text"
                            @click="
                              toUserPolcies();
                              clickedFieldGlobal(
                                'goToUserPolciesBtn',
                                89,
                                'Afficiency Survey v1'
                              );
                            "
                          >
                            My Policies.
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        :class="
                          mobileDevice ? 'justify-center' : 'justify-end pa-6'
                        "
                        class="d-flex"
                      >
                        <v-btn
                          large
                          right
                          class="primary font-weight-bold mt-4 mb-2"
                          to="/userpolicies"
                        >
                          View My Policies
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-row
            v-if="stepperStep === 2"
            class="d-flex justify-center pt-6 pa-2"
          >
            <v-col
              cols="12"
              sm="10"
              class="d-flex justify-center"
            >
              <p class="text-center">
                Policies are issued by The Savings Bank Mutual Life Insurance
                Company of Massachusetts (“SBLI”) on policy form series
                20-P-SIT. Centrian Life Insurance is a registered trade name and
                Connecticut d/b/a of SBLI. Policies sold in the state of
                Connecticut will be issued under Centrian Life Insurance
                ("Centrian"). The premium quotes displayed here are non-binding
                and based on some preliminary personal information. The final
                insurance policy premium for any policy is determined following
                the applicant completing the full application process.
              </p>
            </v-col>
          </v-row>
          <v-row
            v-if="stepperStep !== 2"
            class="d-flex justify-center pt-6 pa-2"
          >
            <v-col
              cols="12"
              sm="10"
              class="d-flex justify-center"
            >
              <p class="text-center">
                Policies are issued by The Savings Bank Mutual Life Insurance
                Company of Massachusetts (“SBLI”) on policy form series
                20-P-SIT. Centrian Life Insurance is a registered trade name and
                Connecticut d/b/a of SBLI. Policies sold in the state of
                Connecticut will be issued under Centrian Life Insurance
                ("Centrian").
              </p>
            </v-col>
            <v-col
              v-if="user.state === 'CT'"
              cols="12"
            >
              <v-img
                src="/img/term-life-2022/Centrian-Footer-Logo.jpg"
                class="mx-auto"
                height="150"
                width="250"
              ></v-img>
            </v-col>
            <v-col
              v-if="user.state !== 'CT'"
              cols="12"
            >
              <v-img
                src="/img/term-life-2022/SBLI-Footer-Logo.jpg"
                class="mx-auto"
                height="150"
                width="250"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="quoteSaveDialog"
          transition="dialog-bottom-transition"
          width="auto"
        >
          <v-card class="pa-4">
            <v-card-title
              class="justify-center font-weight-bold text-h5 green--text"
            >
              Success!
            </v-card-title>
            <v-card-text class="text-center">
              Your quote was saved successfully.
            </v-card-text>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold mr-0"
                    @click="
                      quoteSaveDialog = false;
                      clickedFieldGlobal(
                        'closeQuoteSaveDialog',
                        90,
                        'Afficiency Survey v1'
                      );
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="quoteSaveErrorDialog"
          persistent
          transition="dialog-bottom-transition"
          width="auto"
        >
          <v-card>
            <v-card-title
              class="justify-center font-weight-bold text-h5 green--text"
            >
              Error
            </v-card-title>
            <v-card-title
              class="justify-center text-center body primmary--text mt-4"
            >
              <v-row>
                <v-col>{{ error }}</v-col>
              </v-row>
            </v-card-title>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      closeQuoteSaveErrorDialog();
                      clickedFieldGlobal(
                        'closeQuoteSaveErrorDialog',
                        91,
                        'Afficiency Survey v1'
                      );
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="paymentErrorDialog"
          persistent
          transition="dialog-bottom-transition"
          width="auto"
        >
          <v-card>
            <v-card-title class="justify-center font-weight-bold text-h5">
              Payment Error
            </v-card-title>
            <v-card-title
              class="justify-center text-center body primmary--text mt-4"
            >
              <v-row>
                <v-col>{{ paymentError }}</v-col>
              </v-row>
            </v-card-title>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      closePaymentErrorDialog();
                      clickedFieldGlobal(
                        'closePaymentErrorDialog',
                        92,
                        'Afficiency Survey v1'
                      );
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="errorDialog"
          max-width="700"
          transition="dialog-bottom-transition"
          width="auto"
          persistent
        >
          <v-card class="pa-4">
            <v-card-title class="justify-center font-weight-bold headline red--text">
              Error
            </v-card-title>
            <v-card-title class="justify-center text-center body primmary--text mt-4">
              <v-row><v-col>{{ error }}</v-col></v-row>
            </v-card-title>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="clearError"
                  >
                    Home
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row> -->
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="errorDialog"
          transition="dialog-bottom-transition"
          max-width="700"
          persistent
        >
          <v-card min-height="33vh">
            <v-card-title class="justify-center font-weight-bold text-h5">
              We are unable to process your request
            </v-card-title>
            <v-card-text class="justify-center text-center body primmary--text">
              <v-row v-if="error">
                <v-col>{{ error }}</v-col>
              </v-row>
            </v-card-text>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-4 mt-4 font-weight-bold"
                    @click="
                      clearError();
                      clickedFieldGlobal(
                        'clearErrReturnToHome',
                        93,
                        'Afficiency Survey v1'
                      );
                    "
                  >
                    Return to Home
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="notApprovedDialog"
          transition="dialog-bottom-transition"
          width="auto"
          persistent
        >
          <v-card class="pa-4">
            <v-card-title class="justify-center font-weight-bold text-h5">
              {{
                mibUrl
                  ? "Unalbe to complete application"
                  : "Declined Application"
              }}
            </v-card-title>
            <v-card-text
              class="justify-center text-center body primmary--text mt-4"
            >
              <v-row>
                <v-col>{{ notApprovedMsg }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <a
                    v-if="uwDecisionNoticeUrl"
                    :href="uwDecisionNoticeUrl"
                    target="_blank"
                  >VIEW NOTICE OF ADVERSE UNDERWRITING DECISION</a>
                  <a
                    v-if="mibUrl"
                    :href="mibUrl"
                    target="_blank"
                    @click="
                      clickedFieldGlobal(
                        'viewMIBNotice',
                        94,
                        'Afficiency Survey v1'
                      )
                    "
                  >VIEW MIB NOTICE</a>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      clearNotApprovedMsg();
                      clickedFieldGlobal(
                        'notApprovedMsgReturnToHome',
                        95,
                        'Afficiency Survey v1'
                      );
                    "
                  >
                    Return To Home
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <otp-verification
      path="src/assets/images/termlife-icon.png"
      filename="termlife-icon.png"
    />
  </v-container>
</template>
<script>
  import moment from 'moment'
  import numeral from 'numeral'
  import Survey from '../components/Afficiency/Survey.vue'
  import PersonallyIdentifiableInformation from '../components/Afficiency/PII.vue'
  import EllipseProgress from '../components/EllipseProgress.vue'
  import AfficiencyBeneficiary from '../components/Afficiency/Beneficiary.vue'
  import PortalOne from '../components/Afficiency/PortalOne.vue'
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  const zipcodes = require('zipcodes')
  export default {
    name: 'QuicktermSurvey',
    components: {
      PersonallyIdentifiableInformation,
      EllipseProgress,
      AfficiencyBeneficiary,
      Survey,
      PortalOne,
      VuePdfEmbed,
      CreateAccountButton,
    },
    // <----------------------------------------DATA---------------------------------------->
    data: () => ({
      unavailable: true,
      paymentErrorDialog: '',
      bankName: '',
      bankAccountNo: '',
      bankAccountType: '',
      bankRoutingNo: '',
      accountTypes: [
        { text: 'Checking', value: 1 },
        { text: 'Savings', value: 2 }
      ],
      achDialog: false,
      disableMakePayment: false,
      offerList: [
        {
          text: 'You have a 20 day review period for your new policy.',
          icon: 'mdi-circle-medium'
        },
        {
          text:
            'There is no penalty for cancelling or replacing this insurance if you decide to take it (subject to cancelling within the terms of the ‘free look’ provision of your policy).',
          icon: 'mdi-circle-medium'
        },
        {
          text: 'Your premium is guaranteed for the length of your term.',
          icon: 'mdi-circle-medium'
        },
        {
          text:
            'After the initial term period (10, 15, or 20 years), you can renew your insurance annually until age 85, at the rates provided in your policy contract.',
          icon: 'mdi-circle-medium'
        }
      ],
      pdfLoaded: false,
      disableBack: false,
      uwDecisionNoticeUrl: '',
      mibUrl: '',
      surveyAnswers: {
        1100: [],
        inches: '',
        feet: ''
      },
      beneficiaries: [],
      answerMap: new Map(),
      uwAnswers: '',
      appSignBox: false,
      paymentFrequency: 'Monthly',
      quoteSaveDialog: false,
      quoteSaveErrorDialog: false,
      notApprovedDialog: false,
      acceptTerms: false,
      show: '',
      errorDialog: false,
      settings: '',
      feet: '',
      inches: '',
      blockSubmit: false,
      windowStep: 1,
      faceAmount: 0,
      termLength: 0,
      stepperStep: 1,
      user: {
        arcId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        gender: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        state: '',
        zipCode: '',
        ssn: '',
        email: '',
        phone: '',
        bday: '',
        bmonth: '',
        byear: '',
        age: '',
        tobaccoUse: '',
        height: '',
        weight: '',
        healthDescription: '',
        coverage: 250000,
        term: '',
        password: '',
        policy: {
          termLength: '',
          amount: '',
          monthlyPrice: ''
        }
      },
      lists: {
        tobacco: [
          { text: 'Never', value: 5 },
          { text: 'More than 36 months ago', value: 4 },
          { text: '25 to 36 months ago', value: 3 },
          { text: '13 to 24 months ago', value: 2 },
          { text: 'Within the last year', value: 1 }
        ],
        healthDescriptions: [
          'I am in exceptional health',
          'My health is great',
          'My health is good',
          'My health is ok'
        ],
        termLengths: ['10 Years', '15 Years', '20 Years']
      }
    }),
    computed: {
      paymentCoverage () {
        return this.premium.approvedCoverage || ''
      },
      paymentTerm () {
        return this.user.term || this.appData.term || ''
      },
      paymentError () {
        let error = ''
        if (this.$store.getters.getAfficiencyPaymentError) {
          error = this.$store.getters.getAfficiencyPaymentError
          this.paymentErrorDialog = true
        }
        return error
      },
      invalidBirthday () {
        let user = this.user
        if (user.bday && user.bmonth && user.byear) {
          let bday = user.bday.toString()
          let bmonth = user.bmonth.toString()
          let byear = user.byear.toString()
          let dob = `${bmonth}-${bday}-${byear}`
          let formatted = moment(dob, 'MMM-DD-YYYY').format('MM/DD/YYYY')
          let valid = moment(formatted, 'MM/DD/YYYY').isValid()
          let isFutureDate = moment(formatted, 'MM/DD/YYYY').isAfter(moment())
          if (!valid || isFutureDate) {
            console.log('****************date not valid')
            return true
          }
          return false
        }
        return false
      },
      finalPdfs () {
        let arr = []
        this.finalListFiles.forEach(file => {
          if (file !== '') {
            arr.push(file)
          }
        })
        return arr
      },
      completedApplication () {
        let complete = false
        if (this.$store.getters.getUSTermCompletedApplication) {
          complete = true
        }
        return complete
      },
      paymentPageReady () {
        if (
          !this.decisionLoading &&
          this.sessionId &&
          this.premium &&
          this.policyNumber
        ) {
          return false
        }
        return true
      },
      policyNumLoading () {
        return this.$store.getters.getAfficiencyPolicyNumLoading
      },
      sessionId () {
        let id = ''
        if (this.$store.getters.getUSTermSessionId) {
          id = this.$store.getters.getUSTermSessionId
        }
        return id
      },
      paymentAmount () {
        let amount = ''
        console.log('paymentFrequency', this.paymentFrequency)
        if (this.paymentFrequency === 'Monthly') {
          amount = numeral(this.premiumMonthly).format('0,0.00')
        } else if (this.paymentFrequency === 'Quarterly') {
          amount = numeral(this.premiumQuarterly).format('0,0.00')
        } else if (this.paymentFrequency === 'SemiAnnually') {
          amount = numeral(this.premiumSemiAnnually).format('0,0.00')
        } else if (this.paymentFrequency === 'Annually') {
          amount = numeral(this.premiumAnnually).format('0,0.00')
        }
        return amount
      },
      premiumMonthly () {
        let premium = ''
        if (this.premium.premiumMonthly) {
          premium = this.premium.premiumMonthly
        }
        return premium
      },
      premiumQuarterly () {
        let premium = ''
        if (this.premium.premiumQuaterly) {
          premium = this.premium.premiumQuaterly
        }
        return premium
      },
      premiumSemiAnnually () {
        let premium = ''
        if (this.premium.premiumSemiAnnually) {
          premium = this.premium.premiumSemiAnnually
        }
        return premium
      },
      premiumAnnually () {
        let premium = ''
        if (this.premium.premiumAnnually) {
          premium = this.premium.premiumAnnually
        }
        return premium
      },
      policyNumber () {
        let num = ''
        if (this.$store.getters.getUSTermPolicyNumber) {
          num = this.$store.getters.getUSTermPolicyNumber
        }
        console.log('POLICY NUMBER', num)
        return num
      },
      appData () {
        let data = ''
        if (this.$store.getters.getAfficiencyAppData) {
          data = this.$store.getters.getAfficiencyAppData
          console.log('SETTING USER DETAILS WITH', data)
          if (data.personalDetails !== undefined) {
            let personalDetails = data.personalDetails
            this.user.coverage = data.coverage
            this.user.term = data.term
            this.user.addressLine1 = personalDetails.addressLine1
            this.user.addressLine2 = personalDetails.addressLine2
            this.user.city = personalDetails.city
            this.user.state = personalDetails.state
            this.user.zipCode = personalDetails.zip
            this.user.firstName = personalDetails.firstName
            this.user.middleName = personalDetails.middleName
            this.user.lastName = personalDetails.lastName
            this.user.dob = personalDetails.dob
            this.user.email = personalDetails.email
            this.user.country = personalDetails.country
            this.user.gender = personalDetails.gender
            this.user.phone = personalDetails.phone
            this.user.ssn = personalDetails.ssn
          }
        }
        return data
      },
      savedQuote () {
        let quote = ''
        if (this.$store.getters.getAfficiencySavedQuote) {
          quote = this.$store.getters.getAfficiencySavedQuote
          console.log('SAVED QUOTE', quote)
        }
        return quote
      },
      statusMessage () {
        return this.$store.getters.getAfficiencyStatusMessage
      },
      statusLoading () {
        return this.$store.getters.getUSTermStatusLoading
      },
      existingApplication () {
        return this.$store.getters.getUSTermExistingApplication
      },
      continueLabel () {
        return this.stepperStep === 2 ? 'Apply Now' : 'Continue'
      },
      premium () {
        return this.$store.getters.getUSTermPremium
      },
      decision () {
        return this.$store.getters.getAfficiencyDecision
      },
      decisionLoading () {
        return this.$store.getters.getUSTermDecisionLoading
      },
      formattedHeight () {
        let feet = this.feet
        let inches = this.inches
        if (feet && inches) {
          if (inches < 10) {
            inches = '0' + inches
          }
          let height = `${feet}.${inches}`
          console.log(height)
          return height
        }
        return null
      },
      nextStep () {
        return this.$store.getters.getAfficiencyNextStep
      },
      notApprovedMsg () {
        if (this.$store.getters.getNotApprovedMsg) {
          this.notApprovedDialog = true
          return this.$store.getters.getNotApprovedMsg
        }
        return false
      },
      pdfsloading () {
        return this.$store.getters.getnoticesloading
      },
      mandatoryInfo () {
        return this.$store.getters.getManadatoryInfo
      },
      userId () {
        let user = this.$store.getters.user
        if (user) {
          return user.id
        }
        return null
      },
      errorMessage () {
        let message = ''
        message = this.$store.getters.getErrors
        return message
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      error () {
        let error = this.$store.getters.error
        if (error) {
          this.errorDialog = true
          return error
        }
        return false
      },
      arcId () {
        return this.$store.getters.arcId
      },
      quote () {
        let quote = this.$store.getters.getUSTermQuote
        return quote
      },
      monthlyEstimate () {
        let monthlyEstimate = this.quote.monthlyQuote
        return monthlyEstimate
      },
      faceAmounts () {
        let arr = [25000, 75000]
        for (let i = 50000; i <= 1000000; i += 50000) {
          arr.push(i)
        }
        arr.sort((a, b) => a - b)
        return arr
      },
      currentTitle () {
        switch (this.windowStep) {
          case 1:
            return 'Sign-up'
          case 2:
            return 'Create a password'
          default:
            return 'Account created'
        }
      },
      surveyQuestions () {
        let orderedQuestions = this.$store.getters.getAfficiencyQuestions.sort(
          (a, b) => a.question_sequence_number - b.question_sequence_number
        )
        return orderedQuestions
      },
      amount () {
        let amount = this.faceAmounts[this.faceAmount]
        this.user.policy.amount = amount
        return amount
      },
      faceValue () {
        return this.faceAmounts[this.faceAmount]
      },
      term () {
        let term = 0
        if (this.lists.termLengths[this.termLength] === '10 Years') {
          term = 10
        } else if (this.lists.termLengths[this.termLength] === '15 Years') {
          term = 15
        } else if (this.lists.termLengths[this.termLength] === '20 Years') {
          term = 20
        }
        return term
      },
      price () {
        return this.user.policy.monthlyPrice
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      allzips () {
        let usazips = zipcodes.radius(67052, 1500)
        let codes = usazips.slice(0, 4)
        return usazips
      },
      yearsavailable () {
        let maxyear = Number(moment().format('YYYY'))
        let startingyear = maxyear - 120
        let years = []
        for (let i = startingyear; i <= maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      noticeListFiles () {
        let cloudNotices = []
        cloudNotices = this.$store.getters.getUSTermPdfs
        let consumerPrivacyKey = encodeURI('906.pdf')
        let privacyNotice = ''
        let UwProcessKey = encodeURI('912.pdf')
        let UwProcessNotice = ''
        let electronicDisclosuresKey = encodeURI('911.pdf')
        let electronicDisclosure = ''
        let infoPractKey = encodeURI('904.pdf')
        let infoPractNotice = ''
        let hippaKey = encodeURI('905.pdf')
        let hippaNotice = ''

        cloudNotices.forEach(entry => {
          let priv = entry.url.includes(consumerPrivacyKey)
          let uwprocess = entry.url.includes(UwProcessKey)
          let eledisc = entry.url.includes(electronicDisclosuresKey)
          let infoprac = entry.url.includes(infoPractKey)
          let hippa = entry.url.includes(hippaKey)
          if (priv) {
            privacyNotice = entry.url
          }
          if (uwprocess) {
            UwProcessNotice = entry.url
          }
          if (eledisc) {
            electronicDisclosure = entry.url
          }
          if (infoprac) {
            infoPractNotice = entry.url
          }
          if (hippa) {
            hippaNotice = entry.url
          }
        })

        let files = [
          {
            name: 'HIPAA Authorization to Collect and Disclose Information',
            url: hippaNotice,
            ack: ''
          },
          {
            name:
              'Policy E-Application and E-Delivery Authorization and Disclosure',
            url: electronicDisclosure.toString(),
            ack: ''
          },
          { name: 'Consent and Agreement', url: UwProcessNotice, ack: '' },
          { name: 'Notice Information Practices', url: infoPractNotice, ack: '' },
          { name: 'Privacy Policy', url: privacyNotice.toString(), ack: '' }
        ]

        return files
      },
      finalListFiles () {
        let cloudNotices = []
        cloudNotices = this.$store.getters.getUSTermPdfs
        let consumerPrivacyKey = encodeURI('906.pdf')
        let privacyNotice = ''
        let UwProcessKey = encodeURI('912.pdf')
        let UwProcessNotice = ''
        let electronicDisclosuresKey = encodeURI('911.pdf')
        let electronicDisclosure = ''
        let infoPractKey = encodeURI('904.pdf')
        let infoPractNotice = ''
        let hippaKey = encodeURI('905.pdf')
        let hippaNotice = ''
        let insruanceApplicationKey = encodeURI('907.pdf')
        let insuranceApplicationNotice = ''
        let underwritingDecisionKey = encodeURI('913.pdf')
        let underwritingDecisionNotice = ''
        let MIBKey = encodeURI('914.pdf')
        let MIBNotice = ''
        let replacementNoticeKey = encodeURI('910.pdf')
        let replacementNotice = ''
        let disclosureStatementKey = encodeURI('920.pdf')
        let disclosureStatement = ''
        let applicationEndorsementKey = encodeURI('919.pdf')
        let applicationEndorsement = ''

        cloudNotices.forEach(entry => {
          let priv = entry.url.includes(consumerPrivacyKey)
          let uwprocess = entry.url.includes(UwProcessKey)
          let eledisc = entry.url.includes(electronicDisclosuresKey)
          let infoprac = entry.url.includes(infoPractKey)
          let hippa = entry.url.includes(hippaKey)
          let app = entry.url.includes(insruanceApplicationKey)
          let uwdec = entry.url.includes(underwritingDecisionKey)
          let mib = entry.url.includes(MIBKey)
          let rep = entry.url.includes(replacementNoticeKey)
          let disc = entry.url.includes(disclosureStatementKey)
          let appEnd = entry.url.includes(applicationEndorsementKey)
          if (priv) {
            privacyNotice = entry.url
          }
          if (uwprocess) {
            UwProcessNotice = entry.url
          }
          if (eledisc) {
            electronicDisclosure = entry.url
          }
          if (infoprac) {
            infoPractNotice = entry.url
          }
          if (hippa) {
            hippaNotice = entry.url
          }
          if (app) {
            insuranceApplicationNotice = entry.url
          }
          if (uwdec) {
            underwritingDecisionNotice = entry.url
            this.uwDecisionNoticeUrl = underwritingDecisionNotice.toString()
          }
          if (mib) {
            MIBNotice = entry.url
            this.mibUrl = MIBNotice.toString()
          }
          if (rep) {
            replacementNotice = entry.url
          }
          if (disc) {
            disclosureStatement = entry.url
          }
          if (appEnd) {
            applicationEndorsement = entry.url
          }
        })

        let files = [
          {
            name: 'HIPAA Authorization to Collect and Disclose Information',
            url: hippaNotice,
            ack: ''
          },
          {
            name:
              'Policy E-Application and E-Delivery Authorization and Disclosure',
            url: electronicDisclosure.toString(),
            ack: ''
          },
          { name: 'Consent and Agreement', url: UwProcessNotice, ack: '' },
          { name: 'Notice Information Practices', url: infoPractNotice, ack: '' },
          { name: 'Privacy Policy', url: privacyNotice.toString(), ack: '' },
          {
            name: 'Replacement Notice',
            url: replacementNotice.toString(),
            ack: ''
          },
          {
            name: 'Disclosure Statement',
            url: disclosureStatement.toString(),
            ack: ''
          },
          {
            name: 'Application Endorsement',
            url: applicationEndorsement.toString(),
            ack: ''
          },
          {
            name: 'Insurance Application',
            url: insuranceApplicationNotice.toString(),
            ack: ''
          },
          underwritingDecisionNotice
            ? {
              name: 'Underwriting Decision',
              url: underwritingDecisionNotice.toString(),
              ack: ''
            }
            : '',
          MIBNotice
            ? { name: 'MIB Authorization', url: MIBNotice.toString(), ack: '' }
            : ''
        ]

        return files
      }
    },
    watch: {
      stepperStep (val) {
        if (val !== 1) {
          console.log('setting afficiency application started to true')
          this.$store.dispatch('setAfficiencyInProgress', true)
        }
      }
    },
    mounted () {
      if (this.savedQuote.term && this.savedQuote.coverage) {
        this.user.term = this.savedQuote.term
        this.user.coverage = this.savedQuote.coverage
      }
    },
    beforeDestroy () {
      this.$store.dispatch('clearError')
      this.$store.dispatch('setAfficiencySavedQuote', '')
    },
    methods: {
      closePaymentErrorDialog () {
        this.$store.dispatch('setAfficiencyPaymentError', '')
        this.paymentErrorDialog = false
      },
      async makeACHPayment () {
        this.achDialog = false
        this.$store
          .dispatch('USTermOfferAcceptance', {
            premium: this.paymentAmount,
            coverage: this.paymentCoverage,
            term: this.paymentTerm
          })
          .then(() => {
            if (!this.error) {
              this.$store
                .dispatch('USTermPaymentACH', {
                  paymentFrequency: this.paymentFrequency,
                  paymentPremium: this.paymentAmount,
                  bankName: this.bankName,
                  bankRoutingNo: this.bankRoutingNo,
                  bankAccountNo: this.bankAccountNo,
                  bankAccountType: this.bankAccountType
                })
                .then(() => {
                  if (!this.paymentError) {
                    this.navigateApplication()
                  }
                })
            }
          })
      },
      scroll () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      backToSurvey () {
        console.log('Survey Answers', this.surveyAnswers)
        console.log('Answer Map', this.answerMap)
        this.stepperStep--
        this.scroll()
      },
      updateAnswers (answers) {
        this.answers = answers
      },
      logAmount () {
        console.log('Payment amount', this.paymentAmount)
      },
      toUserPolcies () {
        this.$router.push('/userpolicies')
      },
      async signApplication () {
        await this.$store.dispatch('USTermAppSign').then(() => {
          if (!this.error) {
            this.$store.dispatch('USTermUpdateSession').then(() => {
              if (!this.error) {
                this.$store
                  .dispatch('getAfficiencyAppStatus', { noArc: false })
                  .then(() => {
                    if (!this.error) {
                      this.stepperStep = 9
                    }
                  })
              }
            })
          }
        })
      },
      closeQuoteSaveErrorDialog () {
        this.quoteSaveErrorDialog = false
        this.$store.dispatch('clearError')
      },
      async saveQuote () {
        this.scroll()
        console.log(this.quoteSaveDialog)
        let payload = {}
        let user = this.user
        if (this.savedQuote && this.savedQuote.gender) {
          payload = {
            existingQuote: true,
            premium: this.monthlyEstimate
              ? this.monthlyEstimate
              : this.savedQuote.premium,
            gender: this.savedQuote.gender,
            dob: this.savedQuote.dob,
            term: user.term,
            zip: this.savedQuote.zip,
            state: this.savedQuote.state,
            height: this.savedQuote.height,
            weight: this.savedQuote.weight,
            tobaccoUse: this.savedQuote.tobaccoUse,
            coverage: user.coverage,
            email: this.savedQuote.email
          }
        } else {
          payload = {
            existingQuote: false,
            gender: user.gender,
            dob: user.dob,
            term: user.term,
            zip: user.zipCode,
            state: user.state,
            height: this.formattedHeight,
            weight: Number(user.weight),
            tobaccoUse: user.tobaccoUse,
            coverage: user.coverage,
            email: user.email,
            premium: this.monthlyEstimate
          }
        }
        this.$store.dispatch('USTermSaveQuote', payload).then(() => {
          if (this.error) {
            this.quoteSaveErrorDialog = true
          } else {
            this.quoteSaveDialog = true
          }
        })
      },
      recalculateQuote () {
        this.scroll()
        const quote = this.savedQuote
        const user = this.user
        const isQuoteSaved = Boolean(quote.gender)
        const payload = {
          gender: isQuoteSaved ? quote.gender : user.gender,
          dob: isQuoteSaved ? quote.dob : user.dob,
          term: user.term,
          zip: isQuoteSaved ? quote.zip : user.zipCode,
          state: isQuoteSaved ? quote.state : user.state,
          height: isQuoteSaved ? quote.height : this.formattedHeight,
          weight: isQuoteSaved ? quote.weight : Number(user.weight),
          tobaccoUse: isQuoteSaved ? quote.tobaccoUse : user.tobaccoUse,
          coverage: user.coverage,
          email: isQuoteSaved ? quote.email : user.email
        }
        this.$store.dispatch('getUSTermQuote', payload)
      },
      async submitAuth () {
        this.scroll()
        await this.$store
          .dispatch('submitUSTermApplicationAnswers', { appQA: this.answers })
          .then(() => {
            this.$store.dispatch('USTermSubmitAuth').then(() => {
              if (this.nextStep === 'BENE') {
                this.stepperStep = 6
              } else {
                this.navigateApplication()
              }
              console.log('user,', this.user)
              this.$store.dispatch('getUSTermDecision', { user: this.user })
            })
          })
      },
      routeHome () {
        this.$router.push('/')
      },
      // Never use goTo for a function name
      async navigateApplication () {
        console.log('next step is ', this.nextStep)
        switch (this.nextStep) {
          case 'PII':
            await this.$store
            this.stepperStep = 3
            this.scroll()
            break // always put .thens on new lines
          case 'UWQ':
            await this.$store
              .dispatch('requestUSTermApplicationQuestions', {
                state: this.user.state
              })
              .then(() => {
                this.stepperStep = 4
              })
            this.scroll()
            break
          case 'BENE':
            this.stepperStep = 6
            this.$store.dispatch('getUSTermDecision', { user: this.user })
            this.scroll()
            break
          case 'PAYMENT':
            this.stepperStep = 7
            this.$store.dispatch('getUSTermDecision', { user: this.user })
            this.scroll()
            break
          case 'SUBMIT':
            this.stepperStep = 8
            this.$store
              .dispatch('USTermGetForms', { stage: 2 })
              .then(() => this.$store.dispatch('loadUSTermDocuments'))
            this.scroll()
            break
          case 'SAVEDQUOTE':
            this.$store.dispatch('setUSTermExistingApplication', false)
            this.stepperStep = 2
            console.log('SAVED QUOTE', this.savedQuote)
            this.user.term = this.savedQuote.term
            this.user.coverage = this.savedQuote.coverage
            this.user.dob = this.savedQuote.dob
            this.user.gender = this.savedQuote.gender
            this.scroll()
            break
          case '':
            this.stepperStep = 8
            this.scroll()
            break
        }
        console.log('USER DETAILS', this.user)
      },
      loginSubmit () {
        // log user in
        console.log('login with ', this.user.email, this.user.password)
        this.$store.dispatch('signUserInAfficiency', {
          email: this.user.email,
          password: this.user.password
        })
      },
      createAccount () {
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.user.email
        })
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      clearNotApprovedMsg () {
        this.$store.dispatch('clearNotApprovedMsg')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      updatePercentage (percentage) {
        if (percentage !== 100) {
          this.percentageError = true
        } else {
          this.percentageError = false
        }
      },
      resetErrorResponse () {
        this.$store.dispatch('clearError')
        this.alertmessage = ''
        this.snackbar = false
      },
      getResidentialAddressData (addressData, placeResultData, id) {
        this.user.addressLine1 = addressData.name
        this.user.city = addressData.locality
        this.user.zipCode = addressData.postal_code
        this.user.state = addressData.administrative_area_level_1
        console.log(this.user.state)
      },
      initApp (payload) {
        this.scroll()
        this.$store.dispatch('USTermInitApplication', payload).then(() => {
          if (!this.error) {
            this.nextquestion('initapplication')
          }
        })
      },
      getQuote () {
        let user = this.user
        let payload = {
          gender: user.gender,
          dob: user.dob,
          term: user.term,
          zip: user.zipCode,
          state: user.state,
          height: this.formattedHeight,
          weight: Number(user.weight),
          tobaccoUse: user.tobaccoUse,
          coverage: user.coverage,
          email: user.email
        }
        this.$store.dispatch('getUSTermQuote', payload).then(() => {
          if (!this.error) {
            this.nextquestion('personalinfo')
          }
        })
      },
      formatBirthday () {
        let user = this.user
        if (user.bday && user.bmonth && user.byear) {
          let bday = user.bday.toString()
          let bmonth = user.bmonth.toString()
          let byear = user.byear.toString()
          let dob = `${bmonth}-${bday}-${byear}`
          let formatted = moment(dob, 'MMM-DD-YYYY').format('MM/DD/YYYY')
          this.user.dob = formatted
          console.log(user)
        }
      },
      main () {
        this.$router.push('/')
      },
      cancel () {
        this.resetErrorResponse()
        this.stepperStep--
        if (this.stepperStep < 1) {
          this.stepperStep = 1
        }
        this.scroll()
      },
      nextquestion (completedQuestion) {
        this.stepperStep++
        console.log('step completed ', completedQuestion)
        this.scroll()
        this.$gtag.event('Afficiency Survey v1', {
          userId: this.userId ? this.userId : 'Anonymous User',
          data_point: `Step completed: ${completedQuestion}`,
          action: 'Step completed',
          data: `${completedQuestion}`
        })
      },
      getpostcodedata (postcode) {
        let loc = zipcodes.lookup(postcode)
        this.user.state = loc.state
        this.$store.dispatch('checkpostcode', postcode)
      },
      postSignup () {
        this.clickedFieldGlobal(
          'Option createAccForMe',
          22,
          'Afficiency Survey v1'
        )
      }
    }
  }
</script>

<style scoped>
::v-deep .v-dialog {
  overflow: hidden !important;
}

.container {
  min-height: 70vh;
}

.test {
  border-width: 1px !important;
  border-color: #0d9eb0 !important;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.declined-title {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  color: #00a1b7;
}

.declined-subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #212121;
}

ol {
  list-style: none;
  counter-reset: item;
}

li {
  counter-increment: item;
  margin-bottom: 5px;
}

li:before {
  margin-right: 10px;
  content: counter(item);
  background: #00a1b7;
  border-radius: 100%;
  color: white;
  width: 1.2em;
  text-align: center;
  display: inline-block;
}

.chip-text {
  font-size: 16px;
}

.chip-text-mobile {
  font-size: 12px;
}

.slider-title {
  font-family: forma-djr-deck, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #616161;
}

.offer-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: #424242;
}

.offer-text-mobile {
  font-weight: 300;
  font-size: 14px;
  line-height: 18.75px;
  color: #424242;
}

.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: white !important;
}

.switch-text {
  font-family: forma-djr-deck, sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  color: #616161;
}

.switch-background {
  color: rgba(0, 161, 183, 0.32);
}

.plan-card {
  background-color: rgba(0, 161, 183, 0.1);
  border: 2px solid #00a1b7;
  border-radius: 6px;
}

.estimate-title {
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0.0025em;
  text-align: center;
}

.estimate-subtitle {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 132.19%;
  color: #757575;
  text-align: center;
}

::v-deep .v-input--selection-controls.v-input .v-label {
  color: black !important;
}

.table-fields {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.table-fields-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.title-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.title-text-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.grey-background {
  background-color: #f5f5f5;
}

.grey-outline {
  border: 1px solid #e0e0e0;
}

.title-subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #212121;
}

.policy-txt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 18px */

  letter-spacing: -0.04em;

  /* Gray/600 */

  color: #757575;
}

::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0 !important;
}

.scrollable {
  overflow-y: scroll;
  height: 60vh;
}

.afficiency-logo-tablet {
  width: 139.36px;
  height: 52.76px;
}
</style>
