<template>
  <div
    class="d-flex justify-center"
    fluid
  >
    <v-row class="d-flex justify-center">
      <v-col class="text-center">
        <v-btn
          class="primary font-weight-bold"
          x-large
          :disabled="disableMakePayment || nextStep === 'SUBMIT' || loading"
          @click="
            makePayment();
            clickedFieldGlobal(
              'PortalOne makeCCPayment',
              80,
              'Afficiency Survey v1'
            );
          "
        >
          Accept & Make CC Payment
        </v-btn>
      </v-col>
    </v-row>
    <div id="portalOneContainer"></div>
  </div>
</template>
<script>
  import moment from 'moment'
  export default {
    props: ['amount', 'frequency', 'error', 'nextStep', 'coverage', 'term'],
    data () {
      return {
        disableMakePayment: false,
        portalOne: null,
      }
    },
    computed: {
      loading () {
        return this.$store.getters.getloading
      },
      policyNumber () {
        let num = ''
        if (this.$store.getters.getUSTermPolicyNumber) {
          num = this.$store.getters.getUSTermPolicyNumber
        }
        console.log('POLICY NUMBER', num)
        return num
      }
    },
    mounted () {
      const container = document.getElementById('portalOneContainer')
      // eslint-disable-next-line no-undef
      this.portalOne = new OneInc.PortalOne(container)
      this.initEventHandlers(container)
      console.log(
        'Amount',
        this.amount,
        'Frequency',
        this.frequency,
        'Error',
        this.error,
        'Next Step',
        this.nextStep,
        'Coverage',
        this.coverage,
        'Term',
        this.term
      )
    },
    methods: {
      initEventHandlers (container) {
        container.addEventListener('portalOne.load', function () {
          console.log('portalOne.load event raised')
        })

        container.addEventListener(
          'portalOne.paymentComplete',
          function (e) {
            console.log('portalOne.paymentComplete event raised', e)
            let transaction = e.detail.transactions[0]
            console.log('TRANSACTION', transaction)
            let payload = {
              tokenId: transaction.tokenId,
              paymentPremium: transaction.paymentAmount,
              paymentType: transaction.cardType,
              timeStamp: transaction.transactionDate,
              paymentFrequency: this.frequency,
              cardExpirationYear: transaction.cardExpirationYear.toString(),
              cardExpirationMonth: transaction.cardExpirationMonth.toString(),
              lastFourDigits: transaction.lastFourDigits.toString()
            }
            this.$store
              .dispatch('USTermOfferAcceptance', {
                premium: transaction.paymentAmount,
                coverage: this.coverage,
                term: this.term
              })
              .then(() => {
                if (!this.error) {
                  this.$store.dispatch('USTermPaymentCC', payload).then(() => {
                    this.disableMakePayment = true
                    this.$emit('complete', true)
                  })
                }
              })
          }.bind(this)
        )

        container.addEventListener('portalOne.error', function (e) {
          // eslint-disable-next-line no-undef
          console.log(e, e.detail)
        })
      },
      ccPay (payload) {
        this.$store.dispatch('USTermPaymentCC', payload)
      },
      makePayment () {
        // console.log($('#portalOneContainer'))
        console.log('AMOUNT', this.amount)
        console.log('Policy number', this.policyNumber)
        this.portalOne.makePayment({
          paymentCategory: 'CreditCard',
          feeContext: 'PaymentWithoutFee',
          amountContext: 'AmountDueOnly',
          amountContextDefault: 'minimumAmountDue',
          minAmountDue: this.amount,
          clientReferenceData1: this.policyNumber,
          confirmationDisplay: 'true',
          saveOption: 'Save',
          acknowledgmentRequired: 'true',
          acceptCreditCards: 'true',
          acceptPrepaidCards: 'true',
          customerId: this.$store.getters.getUSTermClientNumber,
          sessionId: this.$store.getters.getUSTermSessionId,
          isScheduledPayEnabled: true,
          finalPayDate: moment()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          convenienceFeeType: 'Default',
          scheduledPaymentEngineType: 'ScheduledPaymentEngine'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.h-100 {
  min-height: 100vh;
}
</style>
