var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(!_vm.loading)?_c('v-card',{attrs:{"min-height":"300","elevation":"0"}},[_c('div',{staticClass:"text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"},[_vm._v(" Personal Information ")]),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',[_vm._v("Enter your name")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":{
                  required: true,
                  regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                  max: 20
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"First Name","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII first name',
                      29,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Middle Name","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Middle Name","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII middle name',
                      30,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.middleName),callback:function ($$v) {_vm.$set(_vm.user, "middleName", $$v)},expression:"user.middleName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":{ required: true, regex: /^[a-zA-Z\ -]+$/, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Last Name","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII last name',
                      31,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)})],1)],1),_c('p',[_vm._v("Enter your ssn")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"SSN","rules":{ required: true, min: 9, max: 9 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"outlined":"","color":"primary","label":"SSN","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal('PII ssn', 32, 'Afficiency Survey v1')}},model:{value:(_vm.user.ssn),callback:function ($$v) {_vm.$set(_vm.user, "ssn", $$v)},expression:"user.ssn"}})]}}],null,true)})],1)],1),_c('p',[_vm._v("Enter your email address")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Email","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII email',
                      33,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)],1),_c('p',[_vm._v("Enter your phone number")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":{ required: true, min: 10, max: 10 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"outlined":"","color":"primary","label":"Phone","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII phone',
                      34,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Home Address (No P.O. boxes)")]),_c('vuetify-google-autocomplete',{ref:"resaddress",attrs:{"id":"map1","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getaddress,"click":function($event){return _vm.clickedFieldGlobal(
                    'PII res address',
                    35,
                    'Afficiency Survey v1'
                  )}}}),_c('validation-provider',{attrs:{"name":"resaddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Address","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII address',
                      36,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.addressLine1),callback:function ($$v) {_vm.$set(_vm.user, "addressLine1", $$v)},expression:"user.addressLine1"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"City","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal('PII city', 37, 'Afficiency Survey v1')}},model:{value:(_vm.user.city),callback:function ($$v) {_vm.$set(_vm.user, "city", $$v)},expression:"user.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA'),expression:"'AA'"}],attrs:{"error-messages":errors,"label":"State","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII state',
                      38,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.state),callback:function ($$v) {_vm.$set(_vm.user, "state", $$v)},expression:"user.state"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"zipcode","rules":{ required: true, min: 5, max: 5 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"error-messages":errors,"label":"Zipcode","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'PII zipcode',
                      39,
                      'Afficiency Survey v1'
                    )}},model:{value:(_vm.user.zipCode),callback:function ($$v) {_vm.$set(_vm.user, "zipCode", $$v)},expression:"user.zipCode"}})]}}],null,true)})],1)],1)],1)],1),_c('v-row',{staticClass:"pa-2 ma-2"},[_c('v-btn',{staticClass:"orange white--text",attrs:{"x-large":"","disabled":_vm.loading || _vm.existingApplication},on:{"click":function($event){_vm.cancel();
            _vm.clickedFieldGlobal('PII back button', 40, 'Afficiency Survey v1')}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","x-large":"","disabled":invalid || _vm.loading},on:{"click":function($event){_vm.next();
            _vm.clickedFieldGlobal(
              'PII continue button',
              41,
              'Afficiency Survey v1'
            )}}},[_vm._v(" Continue ")])],1)],1):_vm._e(),(_vm.loading)?_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('ellipse-progress',{attrs:{"loading":_vm.loading,"size":180,"img-src":"src/assets/images/termlife-icon.png","thickness":"5","empty-thickness":"3","line-mode":"out 5","legend":"false","animation":"rs 700 1000","font-size":"1.5rem","color":"#00A1B7","empty-color-fill":"transparent","filename":"termlife-icon.png"}})],1)],1):_vm._e()]}}])}),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"auto","persistent":""},model:{value:(_vm.PIIErrorDialog),callback:function ($$v) {_vm.PIIErrorDialog=$$v},expression:"PIIErrorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center font-weight-bold text-h5"},[_vm._v(" Personal Information Error ")]),_c('v-card-title',{staticClass:"justify-center text-center body primmary--text mt-4"},[_c('v-row',[_c('v-col',[_vm._v(_vm._s(_vm.PIIError))])],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mb-2 mt-4 font-weight-bold",attrs:{"color":"primary"},on:{"click":function($event){_vm.clearPIIError();
                    _vm.clickedFieldGlobal(
                      'PII errorCloseBtn',
                      42,
                      'Afficiency Survey v1'
                    )}}},[_vm._v(" Close ")])],1)])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }